import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthorizationNeededGuard } from './shared/guards';

const routes: Routes = [
    {
        path: '',
        pathMatch: 'full',
        loadChildren: () => import('./splash-screen/splash-screen.module').then(module => module.SplashScreenModule),
    },
    {
        path: 'onboarding',
        loadChildren: () => import('./onboarding/onboarding.module').then(module => module.OnboardingModule),
    },
    {
        path: 'home',
        loadChildren: () => import('./home/home.module').then(module => module.HomeModule),
        canActivate: [AuthorizationNeededGuard]
    },
    {
        path: 'exercises',
        loadChildren: () => import('./exercises/exercises.module').then(module => module.ExercisesModule),
        canActivate: [AuthorizationNeededGuard]
    },
    {
        path: 'feed',
        loadChildren: () => import('./feed/feed.module').then(module => module.FeedModule),
        canActivate: [AuthorizationNeededGuard]
    },
    {
        path: 'plans',
        loadChildren: () => import('./plans/plans.module').then(module => module.PlansModule),
        canActivate: [AuthorizationNeededGuard]
    },
    {
        path: 'recipe-books',
        loadChildren: () => import('./recipe-books/recipe-books.module').then(module => module.RecipeBooksModule),
        canActivate: [AuthorizationNeededGuard]
    },
    {
        path: 'meals',
        loadChildren: () => import('./meals/meals.module').then(module => module.MealsModule),
        canActivate: [AuthorizationNeededGuard]
    },
    {
        path: 'progress',
        loadChildren: () => import('./progress/progress.module').then(module => module.ProgressModule),
        canActivate: [AuthorizationNeededGuard]
    },
    {
        path: 'upgrade',
        loadChildren: () => import('./upgrade/upgrade.module').then(module => module.UpgradeModule),
        canActivate: [AuthorizationNeededGuard]
    },
    {
        path: 'profile',
        loadChildren: () => import('./profile/profile.module').then(module => module.ProfileModule),
        canActivate: [AuthorizationNeededGuard]
    },
    {
        path: 'auth',
        loadChildren: () => import('./auth/auth.module').then(module => module.AuthModule)
    },
    { path: '**', redirectTo: '/' }
];

@NgModule({
    imports: [RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })],
    exports: [RouterModule]
})
export class AppRoutingModule { }
