import { Component, OnInit, Renderer2, Inject } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { filter, map, mergeMap } from 'rxjs';
import { AuthService } from './shared/services';
import { TranslateService } from './shared/services/translate.service';
import { DOCUMENT } from '@angular/common';
import { AssetsUrlService } from './shared/services/assets-url.service';

const Navigator: any = {
    userAgentData: navigator['userAgentData'] || navigator['platform']
}
@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
    constructor(
        private authService: AuthService,
        private router: Router,
        private activatedRoute: ActivatedRoute,
        public translate: TranslateService,
        @Inject(DOCUMENT) private document: Document,
        private renderer: Renderer2,
        public assetsUrlService: AssetsUrlService) { }

    get isAuthenticated() {
        return this.authService.isAuthenticated();
    }

    public hidden = false;
    public hasBottomAction = false;

    public isFeedAvailable = false;
    public showPWAInstallOnApple = false;
    public showInstallDesc = false;

    public async ngOnInit() {
        this.showPWAInstallOnApple = this.checkPlatform() && this.getPWADisplayMode() === 'browser';

        if (this.translate.keys?.APP_THEME === 'dark') {
            this.renderer.addClass(this.document.body, 'dark-theme');
        } else if (this.translate?.keys.APP_THEME === 'light') {
            this.renderer.addClass(this.document.body, 'light-theme');
        }

        console.log(this.getPWADisplayMode());

        this.authService.userDataValue.pipe(filter(user => !!user))
            .subscribe(user => {
                this.isFeedAvailable = user.basic.show_feed_button;
            });

        this.router.events.pipe(
            filter(events => events instanceof NavigationEnd),
            map(evt => {
                return this.activatedRoute;
            }),
            map(route => {
                while (route.firstChild) {
                    route = route.firstChild;
                }

                return route;
            }))
            .pipe(
                filter(route => route.outlet === 'primary'),
                mergeMap(route => route.data)
            ).subscribe(x => {

                x['hideNavigation'] === true ? this.hidden = true : this.hidden = false;
                x['hasBottomAction'] === true ? this.hasBottomAction = true : this.hasBottomAction = false;
            })

    }

    private checkPlatform() {
        return [
            'iPad Simulator',
            'iPhone Simulator',
            'iPod Simulator',
            'iPad',
            'iPhone',
            'undefined',
            '', // developer tools - empty platform fix
            'iPod',
            'MacIntel',
            'MacPPC',
            'Mac68K',
            'Macintosh',
        ].includes(Navigator.userAgentData.platform || Navigator.userAgentData);
    }

    private getPWADisplayMode() {
        const isStandalone = window.matchMedia('(display-mode: standalone)').matches;
        if (document.referrer.startsWith('android-app://')) {
            return 'pwa';
        } else if ((navigator as any).standalone || isStandalone) {
            return 'standalone';
        }
        return 'browser';
    }

}
