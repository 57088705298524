import { Component, EventEmitter, Inject, OnDestroy, OnInit, Output } from '@angular/core';
import { Observable, Subject, Subscription } from 'rxjs';
import { BeforeInstallPrompt } from './custom-ui-pwa-install.providers';
import { BeforeInstallPromptEvent } from './before-install-prompt.event';
import { switchMap, mapTo, startWith } from 'rxjs/operators';
@Component({
    selector: 'custom-ui-pwa-install',
    template: `
    <ng-container *ngIf="beforeInstallPromptEvent">
      <ng-container *ngIf="choiceRequired | async">
        <ng-content></ng-content>
      </ng-container>
    </ng-container>
  `
})
export class CustomUIPWAInstallComponent implements OnInit, OnDestroy {

    constructor(
        @Inject(BeforeInstallPrompt)
        private readonly beforeInstallPrompt: Subject<any>) { }

    @Output() prompt = new EventEmitter<BeforeInstallPromptEvent>();

    public beforeInstallPromptEvent: BeforeInstallPromptEvent;

    public readonly choiceRequired: Observable<boolean> = this.beforeInstallPrompt.pipe(
        switchMap(event => event.userChoice),
        mapTo(false),
        startWith(true)
    );

    public ngOnInit(): void {
        this.destroy = this.beforeInstallPrompt.subscribe(
            (event) => {
                if (event && event.platforms) {
                    this.prompt.emit(event);
                }
                this.beforeInstallPromptEvent = event;
            }
        );
    }

    public ngOnDestroy(): void {
        if (!this.destroy) {
            return;
        }
        this.destroy.unsubscribe();
    }

    install() {
        this.beforeInstallPromptEvent.prompt();
    }

    private destroy = new Subscription;
}
