import { Injectable, Injector } from '@angular/core';
import { FitApiClientService } from './fit-api-client.service';

interface Translate {
    APP_THEME: "dark" | "light";
    AND_VERSION_CODE: string;
    AND_SNACK_BAR_TITLE_NO_INTERNET_CONNECTION: string;
    AND_SNACK_BAR_ACTION_NO_INTERNET_CONNECTION: string;
    AND_NO_INTERNET_CONNECTION: string;
    AND_PARSE_RESPONSE_ERROR_MSG: string;
    AND_VALIDATION_EMAIL_WRONG_FORMAT: string;
    AND_VALIDATION_EMAIL_REQUIRED: string;
    AND_VALIDATION_PASSWORD_REQUIRED: string;
    AND_VALIDATION_PASSWORD_WRONG_LENGTH: string;
    AND_VALIDATION_PASSWORD_WRONG_FORMAT: string;
    AND_VALIDATION_PASSWORD_DONT_MATCH: string;
    AND_ACTIVITY_UPDATE_TITLE: string;
    AND_ACTIVITY_UPDATE_DESCRIPTION: string;
    AND_ACTIVITY_UPDATE_SUBMIT_BUTTON: string;
    AND_ACTIVITY_SERVICE_UNAVAILABLE_TITLE: string;
    AND_ACTIVITY_SERVICE_UNAVAILABLE_DESCRIPTION: string;
    AND_ACTIVITY_SERVICE_UNAVAILABLE_BUTTON_TEXT: string;
    AND_ACTIVITY_INSTRUCTION_GOALS_TITLE: string;
    AND_ACTIVITY_INSTRUCTION_GOALS_DESCRIPTION: string;
    AND_ACTIVITY_INSTRUCTION_DIET_TITLE: string;
    AND_ACTIVITY_INSTRUCTION_DIET_DESCRIPTION: string;
    AND_ACTIVITY_INSTRUCTION_COMMUNITY_TITLE: string;
    AND_ACTIVITY_INSTRUCTION_COMMUNITY_DESCRIPTION: string;
    AND_ACTIVITY_INSTRUCTION_PROGRAM_TITLE: string;
    AND_ACTIVITY_INSTRUCTION_PROGRAM_DESCRIPTION: string;
    AND_ACTIVITY_INSTRUCTION_CREATE: string;
    AND_ACTIVITY_INSTRUCTION_SIGN_IN: string;
    AND_SIGNUP_ACTIVITY_TITLE: string;
    AND_SIGNUP_CHOOSER_FRAGMENT_LABEL_EMAIL: string;
    AND_SIGNUP_CHOOSER_FRAGMENT_TEXT_EMAIL: string;
    AND_SIGNUP_CHOOSER_FRAGMENT__GOOGLE: string;
    AND_SIGNUP_CHOOSER_FRAGMENT__FACEBOOK: string;
    AND_INVALID_GOOGLE_TOKEN_ERROR_TEXT: string;
    AND_CREATE_ACCOUNT_FRAGMENT_HAVE_ACCOUNT: string;
    AND_CREATE_ACCOUNT_FRAGMENT_FIRST_NAME: string;
    AND_CREATE_ACCOUNT_FRAGMENT_LAST_NAME: string;
    AND_CREATE_ACCOUNT_FRAGMENT_USERNAME: string;
    AND_CREATE_ACCOUNT_FRAGMENT_EMAIL: string;
    AND_CREATE_ACCOUNT_FRAGMENT_PASSWORD: string;
    AND_CREATE_ACCOUNT_FRAGMENT_CONFIRM_PASS: string;
    AND_CREATE_ACCOUNT_FRAGMENT_ACCEPT: string;
    AND_CREATE_ACCOUNT_FRAGMENT_TERMS: string;
    AND_CREATE_ACCOUNT_REQUEST_FIRST_NAME_REQUIRED: string;
    AND_CREATE_ACCOUNT_REQUEST_LAST_NAME_REQUIRED: string;
    AND_CREATE_ACCOUNT_REQUEST_USER_NAME_REQUIRED: string;
    AND_SUCCESS_CREATE_ACCOUNT_FRAGMENT_DESC: string;
    AND_FORGOT_PASSWORD_FRAGMENT_TITLE: string;
    AND_FORGOT_PASSWORD_FRAGMENT_SEND: string;
    AND_FORGOT_PASSWORD_FRAGMENT_DESC: string;
    AND_FORGOT_PASSWORD_FRAGMENT_EMAIL: string;
    AND_RESET_PASSWORD_FRAGMENT_TITLE: string;
    AND_RESET_PASSWORD_FRAGMENT_SUBMIT: string;
    AND_RESET_PASSWORD_FRAGMENT_PASSWORD: string;
    AND_RESET_PASSWORD_FRAGMENT_CONFIRM_PASS: string;
    AND_RESET_PASSWORD_FRAGMENT_PASSWORD_DESCRIPTION: string;
    AND_RESET_PASSWORD_FRAGMENT_OTP_DESCRIPTION: string;
    AND_SUCCESS_FORGOT_FRAGMENT_DESC: string;
    AND_SIGN_IN_ACTIVITY_TITLE: string;
    AND_SIGN_IN_ACTIVITY_LABEL_EMAIL: string;
    AND_SIGN_IN_ACTIVITY_FORGOT: string;
    AND_SIGN_IN_ACTIVITY_EMAIL: string;
    AND_SIGN_IN_ACTIVITY_PASSWORD: string;
    AND_SIGN_IN_ACTIVITY_SUBMIT: string;
    AND_SIGN_IN_ACTIVITY_CREATE: string;
    AND_SIGN_IN_ACTIVITY_GOOGLE: string;
    AND_SIGN_IN_ACTIVITY_FACEBOOK: string;
    AND_SIGN_IN_ACTIVITY_MAIL_SENT: string;
    AND_SIGN_IN_ACTIVITY_MAIL_NOT_SENT: string;
    AND_RESEND_DIALOG_TITLE: string;
    AND_RESEND_DIALOG_DESCRIPTION: string;
    AND_RESEND_DIALOG_CANCEL: string;
    AND_RESEND_DIALOG_RESEND: string;
    AND_CHANGE_PASSWORD_ACTIVITY_TITLE: string;
    AND_CHANGE_PASSWORD_ACTIVITY_SUBMIT: string;
    AND_CHANGE_PASSWORD_ACTIVITY_OLD_PASS: string;
    AND_CHANGE_PASSWORD_ACTIVITY_NEW_PASS: string;
    AND_CHANGE_PASSWORD_ACTIVITY_CONFIRM_PASS: string;
    AND_CHANGE_PASSWORD_ACTIVITY_SUCCESS_CHANGED: string;
    AND_SETTINGS_FRAGMENT_TOOLBAR_TITLE_TEXT: string;
    AND_SETTINGS_FRAGMENT_YOUR_ACCOUNT_TEXT: string;
    AND_SETTINGS_FRAGMENT_ABOUT_TEXT: string;
    AND_SETTINGS_FRAGMENT_RATE_TEXT: string;
    AND_SETTINGS_FRAGMENT_UPGRADE_TO_PRO_TEXT: string;
    AND_SETTINGS_FRAGMENT_LOG_OUT_TEXT: string;
    AND_SETTINGS_FRAGMENT_SUCCESS_LOG_OUT_TEXT: string;
    AND_USER_DETAILS_FRAGMENT_TOOLBAR_TITLE_TEXT: string;
    AND_USER_DETAILS_FRAGMENT_FIRST_NAME_TEXT: string;
    AND_USER_DETAILS_FRAGMENT_LAST_NAME_TEXT: string;
    AND_USER_DETAILS_FRAGMENT_BIO_TEXT: string;
    AND_USER_DETAILS_FRAGMENT_GENDER_TEXT: string;
    AND_USER_DETAILS_FRAGMENT_DATE_OF_BIRTH_TEXT: string;
    AND_USER_DETAILS_FRAGMENT_HEIGHT_TEXT: string;
    AND_USER_DETAILS_FRAGMENT_WEIGHT_TEXT: string;
    AND_USER_DETAILS_FRAGMENT_GOAL_TEXT: string;
    AND_USER_DETAILS_FRAGMENT_LEVEL_TEXT: string;
    AND_USER_DETAILS_FRAGMENT_ENVIRONMENT_TEXT: string;
    AND_USER_DETAILS_FRAGMENT_DIETARY_GOAL_TEXT: string;
    AND_USER_DETAILS_FRAGMENT_FOOD_PREFERENCE_TEXT: string;
    AND_ACCOUNT_FRAGMENT_TOOLBAR_TITLE_TEXT: string;
    AND_ACCOUNT_FRAGMENT_EMAIL_TEXT: string;
    AND_ACCOUNT_FRAGMENT_CHANGE_PASSWORD_TEXT: string;
    AND_ACCOUNT_FRAGMENT_UNITS_TEXT: string;
    AND_ACCOUNT_FRAGMENT_SUCCESS_UPDATE_USER_TEXT: string;
    AND_ABOUT_FRAGMENT_TOOLBAR_TITLE_TEXT: string;
    AND_ABOUT_FRAGMENT_TERMS_TEXT: string;
    AND_ABOUT_FRAGMENT_TERMS_TITLE_TEXT: string;
    AND_ABOUT_FRAGMENT_PRIVACY_TITLE_TEXT: string;
    AND_ABOUT_FRAGMENT_PRIVACY_TEXT: string;
    AND_LOG_OUT_DIALOG_HEADER_TITLE_TEXT: string;
    AND_LOG_OUT_DIALOG_DESCRIPTION_TEXT: string;
    AND_LOG_OUT_DIALOG_FOOTER_CANCEL_TEXT: string;
    AND_LOG_OUT_DIALOG_FOOTER_SUBMIT_TEXT: string;
    AND_EDIT_HEIGHT_DIALOG_HEADER_TITLE_TEXT: string;
    AND_EDIT_HEIGHT_DIALOG_FOOTER_CANCEL_TEXT: string;
    AND_EDIT_HEIGHT_DIALOG_FOOTER_SUBMIT_TEXT: string;
    AND_EDIT_HEIGHT_DIALOG_REQUIRED_INPUT_TEXT: string;
    AND_EDIT_OPTION_PICKER_DIALOG_CANCEL_TEXT: string;
    AND_EDIT_OPTION_PICKER_DIALOG_SAVE_TEXT: string;
    AND_EDIT_OPTION_PICKER_GENDER_TITLE_TEXT: string;
    AND_EDIT_OPTION_PICKER_GOAL_TITLE_TEXT: string;
    AND_EDIT_OPTION_PICKER_LEVEL_TITLE_TEXT: string;
    AND_EDIT_OPTION_PICKER_ENVIRONMENT_TITLE_TEXT: string;
    AND_EDIT_OPTION_PICKER_DIETARY_GOAL_TITLE_TEXT: string;
    AND_EDIT_OPTION_PICKER_FOOD_PREFERENCE_TITLE_TEXT: string;
    AND_EDIT_OPTION_PICKER_ERROR_SELECT: string;
    AND_EDIT_WEIGHT_DIALOG_TITLE_TEXT: string;
    AND_EDIT_WEIGHT_DIALOG_CANCEL_TEXT: string;
    AND_EDIT_WEIGHT_DIALOG_SAVE_TEXT: string;
    AND_EDIT_BIO_DIALOG_TITLE_TEXT: string;
    AND_EDIT_BIO_DIALOG_CANCEL_TEXT: string;
    AND_EDIT_BIO_DIALOG_SAVE_TEXT: string;
    AND_EDIT_FIRST_NAME_DIALOG_TITLE_TEXT: string;
    AND_EDIT_FIRST_NAME_DIALOG_CANCEL_TEXT: string;
    AND_EDIT_FIRST_NAME_DIALOG_SAVE_TEXT: string;
    AND_EDIT_LAST_NAME_DIALOG_TITLE_TEXT: string;
    AND_EDIT_LAST_NAME_DIALOG_CANCEL_TEXT: string;
    AND_EDIT_LAST_NAME_DIALOG_SAVE_TEXT: string;
    AND_EDIT_DATE_DIALOG_TITLE_TEXT: string;
    AND_EDIT_DATE_DIALOG_CANCEL_TEXT: string;
    AND_EDIT_DATE_DIALOG_SAVE_TEXT: string;
    AND_CENTIMETER_TEXT: string;
    AND_FEET_TEXT: string;
    AND_INCH_TEXT: string;
    AND_KILOGRAM_TEXT: string;
    AND_POUND_TEXT: string;
    AND_CUSTOM_LOADER_TITLE_TEXT: string;
    AND_FEED_TITLE_TEXT: string;
    AND_COMMENT_TITLE_TEXT: string;
    AND_COMMENT_HINT_TEXT: string;
    AND_COMMENT_COLLAPSED_TEXT: string;
    AND_COMMENT_EXPANDED_TEXT: string;
    AND_COMMENT_REPLY_TEXT: string;
    AND_COMMENT_SENDING: string;
    AND_PROFILE_TITLE_TEXT: string;
    AND_PROFILE_PHOTO_WRONG_EXTENSION_TEXT: string;
    AND_PROFILE_PHOTO_WRONG_SIZE_TEXT: string;
    AND_PROFILE_PHOTO_CHANGE: string;
    AND_PROFILE_PHOTO_ADD: string;
    AND_PROFILE_DAILY_STREAK: string;
    AND_PROFILE_FINISHED_WORKOUTS: string;
    AND_PROFILE_FINISHED_PROGRAMS: string;
    AND_PROFILE_STREAKS: string;
    AND_PROFILE_ACTIVE_PLAN: string;
    AND_PROFILE_CURRENT_DAILY_STREAK: string;
    AND_PROFILE_LONGEST_DAILY_STREAK: string;
    AND_PROFILE_CURRENT_WEEKLY_STREAK: string;
    AND_PROFILE_LONGEST_WEEKLY_STREAK: string;
    AND_PROFILE_ACHIEVEMENTS: string;
    AND_PROFILE_SEE_ALL: string;
    AND_PROFILE_ALL_STARS: string;
    AND_PROFILE_UNLOCK_ALL_REWARD: string;
    AND_PHOTO_DIALOG_HEADER_TITLE_TEXT: string;
    AND_PHOTO_DIALOG_CAMERA_TEXT: string;
    AND_PHOTO_DIALOG_GALLERY_TEXT: string;
    AND_PHOTO_DIALOG_PERMISSION_ERROR_TEXT: string;
    AND_PROGRESS_TITLE_TEXT: string;
    AND_CALENDAR_TITLE_TEXT: string;
    AND_PROGRESS_IMAGES_TITLE_TEXT: string;
    AND_PROGRESS_IMAGES_ADD_YOUR_PHOTO_TEXT: string;
    AND_PROGRESS_IMAGES_SEE_ALL_TEXT: string;
    AND_PROGRESS_IMAGE_FIRST_TEXT: string;
    AND_PROGRESS_IMAGE_LAST_TEXT: string;
    AND_PROGRESS_IMAGE_INACTIVE_ERROR_TEXT: string;
    AND_PROGRESS_NO_IMAGE_TITLE_TEXT: string;
    AND_PROGRESS_NO_IMAGE_DESCRIPTION_TEXT: string;
    AND_PROGRESS_NO_IMAGE_SUBMIT_TEXT: string;
    AND_PROGRESS_ALL_IMAGES_FIRST_ROW_TITLE_TEXT: string;
    AND_PROGRESS_ALL_IMAGES_SECOND_ROW_TITLE_TEXT: string;
    AND_PROGRESS_ALL_PHOTO_SUBMIT: string;
    AND_DELETE_PHOTO_DIALOG_TITLE_TEXT: string;
    AND_DELETE_PHOTO_DIALOG_DESCRIPTION_TEXT: string;
    AND_DELETE_PHOTO_DIALOG_CANCEL_BUTTON_TEXT: string;
    AND_DELETE_PHOTO_DIALOG_SUBMIT_BUTTON_TEXT: string;
    AND_IMAGE_INFO_DIALOG_DESCRIPTION_TEXT: string;
    AND_MY_ACTIVITIES_TITLE_TEXT: string;
    AND_ACTIVITIES_WEEK_AND_DAY_TEXT: string;
    AND_SINGLE_WORKOUT_TITLE_TEXT: string;
    AND_SINGLE_WORKOUT_PRO_TEXT: string;
    AND_SINGLE_WORKOUT_MIN_TEXT: string;
    AND_ACHIEVEMENT_TITLE: string;
    AND_ACHIEVEMENT_UNLOCKED_FRAGMENT_MADE_IT: string;
    AND_ACHIEVEMENT_UNLOCKED_FRAGMENT_ALL_UNLOCKED: string;
    AND_ACHIEVEMENT_UNLOCKED_FRAGMENT_REWARD: string;
    AND_ACHIEVEMENT_UNLOCKED_FRAGMENT_SUBMIT: string;
    AND_MAIN_ACTIVITY_HOME: string;
    AND_MAIN_ACTIVITY_FEED: string;
    AND_MAIN_ACTIVITY_PROGRAMS: string;
    AND_MAIN_ACTIVITY_PROGRESS: string;
    AND_MAIN_ACTIVITY_PROFILE: string;
    AND_EXERCISE_DETAILS_DIALOG_DESCRIPTION: string;
    AND_EXERCISE_DETAILS_DIALOG_GUIDE: string;
    AND_WELCOME_DIALOG_TITLE: string;
    AND_WELCOME_DIALOG_DESCRIPTION: string;
    AND_LIVE_EVENT_ACTIVITY_TITLE: string;
    AND_LIVE_EVENT_ACTIVITY_EVENT_DETAILS: string;
    AND_LIVE_EVENT_ACTIVITY_JOINED_SPOTS_LEFT: string;
    AND_LIVE_EVENT_ACTIVITY_MINS: string;
    AND_LIVE_EVENT_ACTIVITY_EVENT_DESC: string;
    AND_LIVE_EVENT_ACTIVITY_LEAVE_EVENT: string;
    AND_LIVE_EVENT_ACTIVITY_NOT_AVAILABLE: string;
    AND_LIVE_EVENT_ACTIVITY_JOIN_EVENT: string;
    AND_LIVE_EVENT_ACTIVITY_SUCCESS_JOIN: string;
    AND_LIVE_EVENT_ACTIVITY_SUCCESS_LEAVE: string;
    AND_LIVE_EVENT_CALENDAR_ACTIVITY_TITLE: string;
    AND_LIVE_EVENT_CALENDAR_ACTIVITY_NO_SCHEDULED: string;
    AND_LIVE_EVENT_CALENDAR_ACTIVITY_DESCRIPTION: string;
    AND_LIVE_EVENT_ADAPTER_JOIN: string;
    AND_LIVE_EVENT_ADAPTER_RESERVED: string;
    AND_LIVE_EVENT_ADAPTER_LEAVE: string;
    AND_LEAVE_LIVE_EVENT_DIALOG_TITLE: string;
    AND_LEAVE_LIVE_EVENT_DIALOG_DESCRIPTION: string;
    AND_LEAVE_LIVE_EVENT_DIALOG_SUBMIT: string;
    AND_LEAVE_LIVE_EVENT_DIALOG_CANCEL: string;
    AND_RESERVED_LIVE_EVENT_DIALOG_TITLE: string;
    AND_RESERVED_LIVE_EVENT_DIALOG_DESCRIPTION: string;
    AND_RESERVED_LIVE_EVENT_DIALOG_SUBMIT: string;
    AND_BLOG_ACTIVITY_TITLE: string;
    AND_BLOG_ACTIVITY_POST_TITLE: string;
    AND_SELECT_ACTIVE_PROGRAM_DIALOG_TITLE: string;
    AND_SELECT_ACTIVE_PROGRAM_CANCEL: string;
    AND_SELECT_ACTIVE_PROGRAM_DIALOG_SUBMIT: string;
    AND_ACTIVATED_NUTRITION_PROGRAM_ITEM_FRAGMENT_SUBMIT: string;
    AND_ACTIVATED_NUTRITION_PROGRAM_ITEM_FRAGMENT_WEEK: string;
    AND_ACTIVATED_NUTRITION_PROGRAM_ITEM_FRAGMENT_DAY: string;
    AND_ACTIVATED_PROGRAM_ITEM_FRAGMENT_SUBMIT: string;
    AND_ACTIVATED_PROGRAM_ITEM_FRAGMENT_WEEK: string;
    AND_ACTIVATED_PROGRAM_ITEM_FRAGMENT_MINS: string;
    AND_HOME_FRAGMENT_TITLE: string;
    AND_HOME_FRAGMENT_SEE_ALL: string;
    AND_HOME_FRAGMENT_NEXT: string;
    AND_HOME_FRAGMENT_PROGRAM_TITLE: string;
    AND_HOME_FRAGMENT_WORKOUT_TITLE: string;
    AND_HOME_FRAGMENT_MUSCLE_GROUP_TITLE: string;
    AND_HOME_FRAGMENT_LIVE_EVENT_TITLE: string;
    AND_HOME_FRAGMENT_NUTRITION_TITLE: string;
    AND_HOME_FRAGMENT_MEAL_TITLE: string;
    AND_LIVE_EVENT_ITEM_FRAGMENT_JOIN: string;
    AND_LIVE_EVENT_ITEM_FRAGMENT_RESERVED: string;
    AND_LIVE_EVENT_ITEM_FRAGMENT_LEAVE: string;
    AND_MEAL_ITEM_FRAGMENT_MINS: string;
    AND_MEAL_ITEM_FRAGMENT_KCAL: string;
    AND_MEAL_DETAILS_ACTIVITY_TITLE: string;
    AND_MEAL_DETAILS_ACTIVITY_RECIPE_DETAILS: string;
    AND_MEAL_DETAILS_ACTIVITY_DURATION: string;
    AND_MEAL_DETAILS_ACTIVITY_DESCRIPTION: string;
    AND_MEAL_DETAILS_ACTIVITY_INSTRUCTIONS: string;
    AND_MEAL_DETAILS_ACTIVITY_INGREDIENTS: string;
    AND_MEAL_DETAILS_ACTIVITY_PER_SERVING: string;
    AND_MEAL_DETAILS_ACTIVITY_NUTRITIENS: string;
    AND_MEAL_DETAILS_ACTIVITY_CALORIES: string;
    AND_MEAL_DETAILS_ACTIVITY_CALORIES_UNIT: string;
    AND_MEAL_DETAILS_ACTIVITY_PROTEIN: string;
    AND_MEAL_DETAILS_ACTIVITY_PROTEIN_UNIT: string;
    AND_MEAL_DETAILS_ACTIVITY_FAT: string;
    AND_MEAL_DETAILS_ACTIVITY_FAT_UNIT: string;
    AND_MEAL_DETAILS_ACTIVITY_CARBS: string;
    AND_MEAL_DETAILS_ACTIVITY_CARBS_UNIT: string;
    AND_MEAL_DETAILS_ACTIVITY_SUBMIT: string;
    AND_MEAL_DETAILS_ACTIVITY_MAX: string;
    AND_MEAL_DETAILS_ACTIVITY_SUCCESS_LOGGED: string;
    AND_MEAL_SEARCH_ACTIVITY_TITLE: string;
    AND_INSTUCTION_STEP_ADAPTER_STEP: string;
    AND_MEAL_ADAPTER_DURATION: string;
    AND_MEAL_ADAPTER_CALORIES: string;
    AND_CHOOSE_DATE_DIALOG_TITLE: string;
    AND_CHOOSE_DATE_DIALOG_CANCEL: string;
    AND_CHOOSE_DATE_DIALOG_SUBMIT: string;
    AND_LOG_MEAL_DIALOG_DATE: string;
    AND_LOG_MEAL_DIALOG_MEAL: string;
    AND_LOG_MEAL_DIALOG_SERVINGS: string;
    AND_LOG_MEAL_DIALOG_SUBMIT: string;
    AND_NUMBER_OF_SERVING_DIALOG_TITLE: string;
    AND_NUMBER_OF_SERVING_DIALOG_CANCEL: string;
    AND_NUMBER_OF_SERVING_DIALOG_SUBMIT: string;
    AND_NUMBER_OF_SERVING_DIALOG_REQUIRED: string;
    AND_NUMBER_OF_SERVING_DIALOG_RANGE: string;
    AND_BADGE_PRO: string;
    AND_BADGE_ACTIVE_PLAN: string;
    AND_NUTRITION_ACTIVATED_PROGRAM_FRAGMENT_TITLE: string;
    AND_NUTRITION_ACTIVATED_PROGRAM_FRAGMENT_SCHEDULE: string;
    AND_NUTRITION_ACTIVATED_PROGRAM_FRAGMENT_INFO: string;
    AND_NUTRITION_ACTIVATED_PROGRAM_FRAGMENT_PROGRESS_DAY: string;
    AND_NUTRITION_ACTIVATED_PROGRAM_FRAGMENT_DEACTIVATE: string;
    AND_NUTRITION_ACTIVATED_PROGRAM_FRAGMENT_SUBMIT: string;
    AND_NUTRITION_ACTIVATED_PROGRAM_FRAGMENT_SUCCESS_RESET: string;
    AND_NUTRITION_ACTIVATED_PROGRAM_FRAGMENT_SUCCESS_DEACTIVATE: string;
    AND_NUTRITION_DETAILS_FRAGMENT_TITLE: string;
    AND_NUTRITION_DETAILS_FRAGMENT_DETAILS: string;
    AND_NUTRITION_DETAILS_FRAGMENT_CALENDAR: string;
    AND_NUTRITION_DETAILS_FRAGMENT_SUBMIT_CONTINUE: string;
    AND_NUTRITION_DETAILS_FRAGMENT_SUBMIT_ACTIVATE: string;
    AND_NUTRITION_DETAILS_FRAGMENT_PREMIUM_TITLE: string;
    AND_NUTRITION_DETAILS_FRAGMENT_PREMIUM_DESCRIPTION: string;
    AND_NUTRITION_DETAILS_FRAGMENT_PREMIUM_SUBMIT: string;
    AND_NUTRITION_DETAILS_FRAGMENT_DESCRIPTION: string;
    AND_NUTRITION_DETAILS_FRAGMENT_EXPECTED: string;
    AND_NUTRITION_DETAILS_FRAGMENT_SCHEDULE: string;
    AND_NUTRITION_PROGRAM_TASK_ACTIVITY_TITLE: string;
    AND_NUTRITION_PROGRAM_TASK_ACTIVITY_SUBTITLE: string;
    AND_NUTRITION_PROGRAM_TASK_ACTIVITY_TASK_TITLE: string;
    AND_NUTRITION_PROGRAM_TASK_ACTIVITY_DIALOG_TITLE: string;
    AND_NUTRITION_PROGRAM_TASK_ACTIVITY_DIALOG_DESCRIPTION: string;
    AND_UNCHECK_TASK_DIALOG_TITLE: string;
    AND_UNCHECK_TASK_DIALOG_DESCRIPTION: string;
    AND_UNCHECK_TASK_DIALOG_CANCEL: string;
    AND_UNCHECK_TASK_DIALOG_SUBMIT: string;
    AND_TASK_ITEM_FRAGMENT_DURATION: string;
    AND_TASK_ITEM_FRAGMENT_CALORIES: string;
    AND_PROGRAM_TITLE: string;
    AND_PROGRAM_WORKOUT: string;
    AND_PROGRAM_NUTRITION: string;
    AND_WORKOUT_DAY_ADAPTER_DAY: string;
    AND_CHANGE_PLAN_DIALOG_TITLE: string;
    AND_CHANGE_PLAN_DIALOG_DESCRIPTION_WORKOUT: string;
    AND_CHANGE_PLAN_DIALOG_DESCRIPTION_NUTRITION: string;
    AND_CHANGE_PLAN_DIALOG_SUBMIT: string;
    AND_CHANGE_PLAN_DIALOG_CANCEL: string;
    AND_DEACTIVATE_PLAN_DIALOG_TITLE: string;
    AND_DEACTIVATE_PLAN_DIALOG_DESCRIPTION_WORKOUT: string;
    AND_DEACTIVATE_PLAN_DIALOG_DESCRIPTION_NUTRITION: string;
    AND_DEACTIVATE_PLAN_DIALOG_SUBMIT: string;
    AND_DEACTIVATE_PLAN_DIALOG_CANCEL: string;
    AND_RESET_PROGRESS_DIALOG_TITLE: string;
    AND_RESET_PROGRESS_DIALOG_DESCRIPTION_WORKOUT: string;
    AND_RESET_PROGRESS_DIALOG_DESCRIPTION_NUTRITION: string;
    AND_RESET_PROGRESS_DIALOG_SUBMIT: string;
    AND_RESET_PROGRESS_DIALOG_CANCEL: string;
    AND_ACTIVATED_PROGRAM_DETAILS_TITLE: string;
    AND_ACTIVATED_PROGRAM_DETAILS_SCHEDULE: string;
    AND_ACTIVATED_PROGRAM_DETAILS_INFO: string;
    AND_ACTIVATED_PROGRAM_DETAILS_PROGRESS_DAY: string;
    AND_ACTIVATED_PROGRAM_DETAILS_DEACTIVATE: string;
    AND_ACTIVATED_PROGRAM_DETAILS_SUBMIT: string;
    AND_ACTIVATED_PROGRAM_FRAGMENT_SUCCESS_RESET: string;
    AND_ACTIVATED_PROGRAM_FRAGMENT_SUCCESS_DEACTIVATE: string;
    AND_PROGRAM_DETAILS_TITLE: string;
    AND_PROGRAM_DETAILS_DETAILS: string;
    AND_PROGRAM_DETAILS_SUBMIT_CONTINUE: string;
    AND_PROGRAM_DETAILS_SUBMIT_ACTIVATE: string;
    AND_PROGRAM_DETAILS_PREMIUM_TITLE: string;
    AND_PROGRAM_DETAILS_PREMIUM_DESCRIPTION: string;
    AND_PROGRAM_DETAILS_PREMIUM_SUBMIT: string;
    AND_PROGRAM_DETAILS_DESCRIPTION: string;
    AND_PROGRAM_DETAILS_EXPECTED: string;
    AND_PROGRAM_DETAILS_SCHEDULE: string;
    AND_REST_DAY_TITLE: string;
    AND_REST_DAY_WEEK_DAY: string;
    AND_REST_DAY_SUBMIT: string;
    AND_REST_DAY_SUCCESS_COMPLETED: string;
    AND_CUSTOM_ACTIVITIES_CHILD_DETAILS_LEFT_REPS: string;
    AND_CUSTOM_ACTIVITIES_CHILD_DETAILS_LEFT_KCAL: string;
    AND_CUSTOM_ACTIVITIES_CHILD_DETAILS_RIGHT_SERVINGS: string;
    AND_WORKOUT_DETAILS_TITLE: string;
    AND_WORKOUT_DETAILS_DETAILS: string;
    AND_WORKOUT_DETAILS_SUBMIT: string;
    AND_WORKOUT_DETAILS_EXERCISE: string;
    AND_WORKOUT_DETAILS_PREMIUM_TITLE: string;
    AND_WORKOUT_DETAILS_PREMIUM_DESCRIPTION: string;
    AND_WORKOUT_DETAILS_PREMIUM_SUBMIT: string;
    AND_PAYMENT_BOTTOM_DIALOG_TITLE: string;
    AND_PAYMENT_BOTTOM_DIALOG_DESCRIPTION_YEARLY: string;
    AND_PAYMENT_BOTTOM_DIALOG_DESCRIPTION_MONTHLY: string;
    AND_PAYMENT_BOTTOM_DIALOG_GOOGLE: string;
    AND_UPGRADE_NOTIFICATION_FRAGMENT_TITLE: string;
    AND_UPGRADE_NOTIFICATION_FRAGMENT_SUBTITLE: string;
    AND_UPGRADE_NOTIFICATION_FRAGMENT_DESCRIPTION: string;
    AND_UPGRADE_NOTIFICATION_FRAGMENT_SUBMIT: string;
    AND_COMPLETED_PLAN_NOTIFICATION_DIALOG_TITLE: string;
    AND_COMPLETED_PLAN_NOTIFICATION_DIALOG_SUBTITLE: string;
    AND_COMPLETED_PLAN_NOTIFICATION_DIALOG_DESCRIPTION: string;
    AND_COMPLETED_PLAN_NOTIFICATION_DIALOG_SUBMIT: string;
    AND_FINISH_WORKOUT_DIALOG_TITLE: string;
    AND_FINISH_WORKOUT_DIALOG_DESCRIPTION: string;
    AND_FINISH_WORKOUT_DIALOG_CANCEL: string;
    AND_FINISH_WORKOUT_DIALOG_SUBMIT: string;
    AND_WORKOUT_MODE_COUNTDOWN_DIALOG_TITLE: string;
    AND_WORKOUT_MODE_EXERCISE_LIST_TITLE: string;
    AND_WORKOUT_MODE_PICKER_DIALOG_TITLE: string;
    AND_WORKOUT_MODE_PICKER_DIALOG_TIME: string;
    AND_WORKOUT_MODE_PICKER_DIALOG_REPS: string;
    AND_WORKOUT_MODE_PICKER_DIALOG_WEIGHT_UNIT: string;
    AND_WORKOUT_MODE_PICKER_DIALOG_CANCEL: string;
    AND_WORKOUT_MODE_PICKER_DIALOG_SUBMIT: string;
    AND_WORKOUT_MODE_REST_TIME_DIALOG_TITLE: string;
    AND_WORKOUT_MODE_REST_TIME_DIALOG_ACTION_RIGHT: string;
    AND_WORKOUT_FEEDBACK_HARD_TITLE: string;
    AND_WORKOUT_FEEDBACK_LIKE_TITLE: string;
    AND_WORKOUT_FEEDBACK_RATE_TITLE: string;
    AND_WORKOUT_FEEDBACK_COMMENT_HINT: string;
    AND_WORKOUT_FEEDBACK_HARD_OPTION_1: string;
    AND_WORKOUT_FEEDBACK_HARD_OPTION_2: string;
    AND_WORKOUT_FEEDBACK_HARD_OPTION_3: string;
    AND_WORKOUT_FEEDBACK_LIKE_OPTION_1: string;
    AND_WORKOUT_FEEDBACK_LIKE_OPTION_2: string;
    AND_WORKOUT_FEEDBACK_LIKE_OPTION_3: string;
    AND_WORKOUT_FEEDBACK_COMMENT_MAX_CHARS: string;
    AND_WORKOUT_FEEDBACK_SUBMIT: string;
    AND_WORKOUT_MODE_REPS: string;
    AND_WORKOUT_MODE_PERCENT: string;
    AND_WORKOUT_MODE_TIME: string;
    AND_WORKOUT_PROGRESS_IMAGE_FRAGMENT_TITLE: string;
    AND_WORKOUT_PROGRESS_IMAGE_FRAGMENT_DESCRIPTION: string;
    AND_WORKOUT_PROGRESS_IMAGE_FRAGMENT_ADD_PHOTO: string;
    AND_WORKOUT_PROGRESS_IMAGE_FRAGMENT_SUBMIT: string;
    AND_WORKOUT_RESULT_FRAGMENT_TITLE: string;
    AND_WORKOUT_RESULT_FRAGMENT_DESCRIPTION: string;
    AND_WORKOUT_RESULT_FRAGMENT_FIELD_1: string;
    AND_WORKOUT_RESULT_FRAGMENT_FIELD_2: string;
    AND_WORKOUT_RESULT_FRAGMENT_FIELD_3: string;
    AND_WORKOUT_RESULT_FRAGMENT_SUBMIT: string;
    AND_BINDING_ADAPTERS_WEEKS: string;
    AND_BINDING_ADAPTERS_DAYS_WEEK: string;
    AND_BINDING_ADAPTERS_MIN: string;
    AND_BINDING_ADAPTERS_JOINED_LEFT: string;
    AND_BINDING_ADAPTERS_ROUNDS: string;
    AND_BINDING_ADAPTERS_REPS: string;
    AND_BINDING_ADAPTERS_WEIGHT_UNIT: string;
    AND_BINDING_ADAPTERS_MEALS_DAY: string;
    AND_BINDING_ADAPTERS_SET_OF: string;
    AND_BINDING_ADAPTERS_ROUND_OF: string;
    AND_BINDING_ADAPTERS_KCAL: string;
    AND_BINDING_ADAPTERS_REST_DAY: string;
    AND_FOLLOW_SCHEDULE_DIALOG_TITLE: string;
    AND_FOLLOW_SCHEDULE_DIALOG_SUBMIT: string;
    AND_FOLLOW_SCHEDULE_DIALOG_DESCRIPTION_WORKOUT: string;
    AND_FOLLOW_SCHEDULE_DIALOG_DESCRIPTION_NUTRITION: string;
    iOS_cancel: string;
    iOS_ok: string;
    iOS_error: string;
    iOS_errorTitle: string;
    iOS_seeAll: string;
    iOS_continue: string;
    iOS_success: string;
    iOS_save: string;
    iOS_errorMessage: string;
    iOS_nutritionPlanDetailsLabel: string;
    iOS_workoutPlanDetailsLabel: string;
    iOS_workoutDetailsLabel: string;
    iOS_feed: string;
    iOS_blog: string;
    iOS_reply: string;
    iOS_comments: string;
    iOS_yourComment: string;
    iOS_shareThisPostOn: string;
    iOS_home: string;
    iOS_liveEventsDetailsLabel: string;
    iOS_liveEventsLabel: string;
    iOS_recipeDetailsLabel: string;
    iOS_settingsTitle: string;
    iOS_privacyPolicy: string;
    iOS_plans: string;
    iOS_profile: string;
    iOS_yourAccount: string;
    iOS_aboutFitIn: string;
    iOS_upgrade: string;
    iOS_welcomeTitle: string;
    iOS_welcomeDescription: string;
    iOS_day: string;
    iOS_nutritionDay: string;
    iOS_week: string;
    iOS_weeks: string;
    iOS_daysAweek: string;
    iOS_nextOnSchedule: string;
    iOS_continueYourProgress: string;
    iOS_browseWorkoutPlans: string;
    iOS_browseNutritionPlans: string;
    iOS_liveJoinedSpotsLabel: string;
    iOS_liveJoinLabel: string;
    iOS_liveEventJoin: string;
    iOS_liveEventLeave: string;
    iOS_mealsADay: string;
    iOS_liveSectionHeader: string;
    iOS_plansSectionHeader: string;
    iOS_workoutsSectionHeader: string;
    iOS_exercisesSectionHeader: string;
    iOS_nutritionProgramsSectionHeader: string;
    iOS_mealsSectionHeader: string;
    iOS_yearlyPro: string;
    iOS_monthlyPro: string;
    iOS_popular: string;
    iOS_active: string;
    iOS_month: string;
    iOS_yogaPlansAndWorkouts: string;
    iOS_getImmediateAccessToPremiumYogaPlansAndWorkouts: string;
    iOS_joinLiveEvent: string;
    iOS_joinScheduledLiveEventsAndGetAnExclusiveOpportunityToTrainsWith: string;
    iOS_enjoyPersonalRecipes: string;
    iOS_upgradeToProAndGetAccessToALargeNumberOfPersonalRecipes: string;
    iOS_manageYourSubscriptionsFromYour: string;
    iOS_appStoreSubscriptionSettings: string;
    iOS_restoreSubscriptions: string;
    iOS_proMembersOnlyTitle: string;
    iOS_proMembersOnlyDescription: string;
    iOS_thisPackageIsAlradyActivated: string;
    iOS_welcomeToProTeam: string;
    iOS_enjoyYourMonthlyProSubscription: string;
    iOS_enjoyYourYearlyProSubscription: string;
    iOS_exploreExclusiveYogaPlansWorkoutsRecipesAndMuchMore: string;
    iOS_startYourProJourney: string;
    iOS_progress: string;
    iOS_activityCalendarTitleLabel: string;
    iOS_workoutsLabel: string;
    iOS_recipesLabel: string;
    iOS_addBeforePhotoLabel: string;
    iOS_addAfterPhotoLabel: string;
    iOS_progressImages: string;
    iOS_myActivities: string;
    iOS_addPhoto: string;
    iOS_addYourPhoto: string;
    iOS_noPhotosAdded: string;
    iOS_noPhotosAddedDesc: string;
    iOS_deleteProgressImage: string;
    iOS_deleteImage: string;
    iOS_areYouSureDeleteProgressImage: string;
    iOS_uploadYourPostWorkoutPhotos: string;
    iOS_progressImageSelectionTitle: string;
    iOS_progressImageSelectionDesc: string;
    iOS_yourTransformation: string;
    iOS_allProgressImages: string;
    iOS_yourFirstImage: string;
    iOS_yourLastImage: string;
    iOS_firstAddPostWorkoutPhotoOnTheLeftCard: string;
    iOS_addProfileImageLabel: string;
    iOS_changeProfileImageLabel: string;
    iOS_activePlan: string;
    iOS_streaks: string;
    iOS_currentDailyStreak: string;
    iOS_longestDailyStreak: string;
    iOS_currentWeeklyStreak: string;
    iOS_longestWeeklyStreak: string;
    iOS_emailAddress: string;
    iOS_firstName: string;
    iOS_lastName: string;
    iOS_changePassword: string;
    iOS_changePlan: string;
    iOS_units: string;
    iOS_logout: string;
    iOS_Logout_question: string;
    iOS_areYouSureYouWantToLogOut: string;
    iOS_noNameEntry: string;
    iOS_noLastNameEntry: string;
    iOS_noTerms: string;
    iOS_registerSuccessTitle: string;
    iOS_finishedPlansLabel: string;
    iOS_finishedWorkoutsLabel: string;
    iOS_currentStreakLabel: string;
    iOS_editBase: string;
    iOS_emailCannotBeChanged: string;
    iOS_selectPicture: string;
    iOS_selectPictureFromGallery: string;
    iOS_takePictureWithCamera: string;
    iOS_cameraPermissionRequired: string;
    iOS_noAccessToTheCamera: string;
    iOS_openCameraSettingsPermission: string;
    iOS_achievements: string;
    iOS_allStarsAchievementTitle: string;
    iOS_allStarsAchievementDescription: string;
    iOS_youHaveMadeIt: string;
    iOS_allAchievementsUnlocked: string;
    iOS_youHaveSuccessfullyUnlockedAllAchievements: string;
    iOS_achievementUnlocked: string;
    iOS_wellDoneYouHaveUnlockedAchievement: string;
    iOS_rateFitin: string;
    iOS_termsOfUse: string;
    iOS_forgotPassword: string;
    iOS_resetPassword: string;
    iOS_login: string;
    iOS_createAccount: string;
    iOS_activateYourAccount: string;
    iOS_activateYourAccountMsg: string;
    iOS_alreadyHaveAcc: string;
    iOS_iAcceptTC: string;
    iOS_iAcceptTCFirstPart: string;
    iOS_iAcceptTCSecondPart: string;
    iOS_dontHaveAcc: string;
    iOS_emailFieldIsMandatory: string;
    iOS_passwordFieldIsMandatory: string;
    iOS_passwordTooShort: string;
    iOS_emailNotValid: string;
    iOS_orWithLabel: string;
    iOS_sendVerificationCode: string;
    iOS_resendEmail: string;
    iOS_sendVerifyEmail: string;
    iOS_verificationCode: string;
    iOS_enterNewPassLabel: string;
    iOS_newPassPlaceholder: string;
    iOS_newPassConfirmPlaceholder: string;
    iOS_codeIncomplete: string;
    iOS_passMatchFail: string;
    iOS_codeResent: string;
    iOS_sendVerificationCodeToResetPassword: string;
    iOS_youHaveSuccessfullyResetYourPassword: string;
    iOS_loginWithApple: string;
    iOS_signUpWithApple: string;
    iOS_loginWithFacebook: string;
    iOS_logoutFromFacebook: string;
    iOS_signUpWithFacebook: string;
    iOS_loginWithGoogle: string;
    iOS_signUpWithGoogle: string;
    iOS_signUpWithEmail: string;
    iOS_orWithLabelSignup: string;
    iOS_forgotPassLabel: string;
    iOS_bothPasswordFieldsAreMandatory: string;
    iOS_notAuthorizedTitle: string;
    iOS_registerSuccessSubTitle: string;
    iOS_passResetSuccess: string;
    iOS_passChangeSuccess: string;
    iOS_notAuthorizedDescription: string;
    iOS_resend: string;
    iOS_email: string;
    iOS_password: string;
    iOS_tasksForDay: string;
    iOS_minutes: string;
    iOS_calories: string;
    iOS_nutriTasks_breakfastOptions: string;
    iOS_nutriTasks_snackOptions: string;
    iOS_nutriTasks_lunchOptions: string;
    iOS_nutriTasks_dinnerOptions: string;
    iOS_nutriTasksPopUp_title: string;
    iOS_nutriTasksPopUp_subtitle: string;
    iOS_nutriTasksPopUp_confirm: string;
    iOS_restDay: string;
    iOS_seePlanInfo: string;
    iOS_workoutPlanStartedOn: string;
    iOS_activateThisPlanBtnTitle: string;
    iOS_deactivateThisPlanBtnTitle: string;
    iOS_deactivatePlanSuccessMessage: string;
    iOS_workoutsExercises: string;
    iOS_workoutsDetails: string;
    iOS_PlanSchedule: string;
    iOS_workoutsKg: string;
    iOS_workoutsPercent: string;
    iOS_workoutsReps: string;
    iOS_startWorkout: string;
    iOS_rounds: string;
    iOS_planDescription: string;
    iOS_ExpectedResults: string;
    iOS_howToPerformThisExercise: string;
    iOS_exerciseDescription: string;
    iOS_completeRestDay: string;
    iOS_workoutResultsMinutes: string;
    iOS_workoutResultsExercises: string;
    iOS_workoutResultsCalories: string;
    iOS_workoutResultsTitle: string;
    iOS_workoutResultsSubtitle: string;
    iOS_workoutDescription: string;
    iOS_exerciseGuideTitle: string;
    iOS_popUp_switchActiveProg_Title: string;
    iOS_popUp_optionWorkout: string;
    iOS_popUp_optionNutrition: string;
    iOS_instructions: string;
    iOS_step: string;
    iOS_recipeDescription: string;
    iOS_nutrients: string;
    iOS_perServing: string;
    iOS_logRecipeDateLabel: string;
    iOS_logRecipeMealLabel: string;
    iOS_logRecipeButton: string;
    iOS_logRecipe_chooseDate: string;
    iOS_logRecipe_chooseNumber: string;
    iOS_logRecipe_chooseMealGroup: string;
    iOS_nutrientCalories: string;
    iOS_nutrientCaloriesUnit: string;
    iOS_nutrientFat: string;
    iOS_nutrientFatUnit: string;
    iOS_nutrientCarbs: string;
    iOS_nutrientCarbsUnit: string;
    iOS_nutrientProteins: string;
    iOS_nutrientProteinsUnit: string;
    iOS_mealDetails_servingsLimitMsg: string;
    iOS_logRecipe_successMsg: string;
    iOS_oldPassPlaceholder: string;
    iOS_editFirstName: string;
    iOS_editLastName: string;
    iOS_editUnit: string;
    iOS_workoutSegmentTitle: string;
    iOS_nutritionSegmentTitle: string;
    iOS_ingredients: string;
    iOS_perServings: string;
    iOS_howHardWasIt: string;
    iOS_easy: string;
    iOS_perfect: string;
    iOS_hard: string;
    iOS_didYouLikeIt: string;
    iOS_no: string;
    iOS_itWasOk: string;
    iOS_yes: string;
    iOS_rateThisWorkout: string;
    iOS_workoutFeedbackPlaceholder: string;
    iOS_sendFeedback: string;
    iOS_finishWorkProg_headline: string;
    iOS_finishWorkProg_title: string;
    iOS_finishWorkProg_subtitle: string;
    iOS_finishWorkProg_actionBtnTitle: string;
    iOS_onBoard1Title: string;
    iOS_onBoard1Description: string;
    iOS_onBoard2Title: string;
    iOS_onBoard2Description: string;
    iOS_onBoard3Title: string;
    iOS_onBoard3Description: string;
    iOS_dialogOk: string;
    iOS_dialogYes: string;
    iOS_dialogNo: string;
    iOS_dialogCancel: string;
    iOS_dialogSend: string;
    iOS_dialogSave: string;
    iOS_noLiveEventsTitle: string;
    iOS_noLiveEventsDesc: string;
    iOS_allSpotsPopUpTitle: string;
    iOS_allSpotsPopUpDesc: string;
    iOS_leaveEventPopUpTitle: string;
    iOS_leaveEventPopUpDesc: string;
    iOS_leaveEventPopUpYes: string;
    iOS_liveDetailsJoinLabel: string;
    iOS_changePlanPopUp_title: string;
    iOS_changePlanPopUp_subtitle: string;
    iOS_changePlanPopUp_confirm: string;
    iOS_postCommentSuccessfully: string;
    AND_ACTIVATED_NUTRITION_PROGRAM_ITEM_FRAGMENT_EMPTY_TITLE: string;
    AND_ACTIVATED_NUTRITION_PROGRAM_ITEM_FRAGMENT_EMPTY_DESCRIPTION: string;
    AND_ACTIVATED_NUTRITION_PROGRAM_ITEM_FRAGMENT_EMPTY_SUBMIT: string;
    AND_ACTIVATED_PROGRAM_ITEM_FRAGMENT_EMPTY_TITLE: string;
    AND_ACTIVATED_PROGRAM_ITEM_FRAGMENT_EMPTY_DESCRIPTION: string;
    AND_ACTIVATED_PROGRAM_ITEM_FRAGMENT_EMPTY_SUBMIT: string;
    AND_ACCOUNT_FRAGMENT_EMAIL_CHANGE: string;
    AND_CREATE_ACCOUNT_FRAGMENT_CONSENT_REQUIRED: string;
    AND_SKIP_SET_DIALOG_TITLE: string;
    AND_SKIP_SET_DIALOG_DESCRIPTION: string;
    AND_SKIP_SET_DIALOG_SUBMIT: string;
    AND_WORKOUT_MODE_AMRAP_FRAGMENT_SKIP: string;
    AND_WORKOUT_MODE_AMRAP_FRAGMENT_ROUND_COUNTER: string;
    AND_BINDING_ADAPTERS_EXERCISE_OF: string;
    AND_BINDING_ADAPTERS_SET_DESCRIPTION_AMRAP: string;
    AND_BINDING_ADAPTERS_SET_DESCRIPTION_AMRAP_SECONDS: string;
    AND_ERROR_INFO_DIALOG_TITLE: string;
    AND_UPGRADE_FRAGMENT_TITLE_FIRST: string;
    AND_UPGRADE_FRAGMENT_DESCRIPTION_FIRST: string;
    AND_UPGRADE_FRAGMENT_TITLE_SECOND: string;
    AND_UPGRADE_FRAGMENT_DESCRIPTION_SECOND: string;
    AND_UPGRADE_FRAGMENT_TITLE_THIRD: string;
    AND_UPGRADE_FRAGMENT_DESCRIPTION_THIRD: string;
    AND_UPGRADE_FRAGMENT_TEXT_WITHOUT_LINK: string;
    AND_UPGRADE_FRAGMENT_TEXT_WITH_LINK: string;
    AND_HOME_FRAGMENT_WORKOUT_PLAN: string;
    AND_HOME_FRAGMENT_NUTRITION_PLAN: string;
    AND_HOME_FRAGMENT_SUCCESS_JOIN: string;
    AND_HOME_FRAGMENT_SUCCESS_LEAVE: string;
    AND_CHOOSE_MEAL_DIALOG_TITLE: string;
    AND_ACCOUNT_FRAGMENT_UNIT_IMPERIAL: string;
    AND_ACCOUNT_FRAGMENT_UNIT_METRIC: string;
    AND_ACCOUNT_FRAGMENT_UNIT_TITLE: string;
    AND_DIALOG_OK: string;
    AND_DIALOG_SAVE: string;
    AND_DIALOG_CANCEL: string;
    AND_LIVE_EVENT_MODEL_AT: string;
    AND_SUBSCRIBE_ADAPTER_MONTH: string;
    AND_WELCOME_ACTIVITY_POWERED_BY: string;
    AND_PROGRAM_DETAILS_CAN_NOT_ACTIVATE: string;
    AND_NUTRITION_DETAILS_FRAGMENT_CAN_NOT_ACTIVATE: string;
    AND_NUTRITION_DAY_ADAPTER_DAY: string;
    AND_CREATE_CONFIRM_DIALOG_TITLE: string;
    AND_CREATE_CONFIRM_DIALOG_DESCRIPTION: string;
    AND_COMMENT_SUCCESS_SENT: string;
    AND_WORKOUT_DETAILS_MINS: string;
    AND_WORKOUT_MODE_GO_BTN: string;
    AND_WORKOUT_MODE_PICKER_DIALOG_DONT_SHOW_AGAIN: string;
    iOS_youAreAlreadySubscribedToThisPackage: string;
    iOS_unknownPrice: string;
    iOS_cantUploadImageAtTheMoment: string;
    iOS_seconds: string;
    iOS_breakfast: string;
    iOS_snack: string;
    iOS_lunch: string;
    iOS_dinner: string;
    iOS_noNutritionPlansActivated: string;
    iOS_whenYouAtivateNutritionPlanYouWellSeeThisWeekSchedule: string;
    iOS_noWorkoutPlansActivated: string;
    iOS_letsGo: string;
    iOS_restTime: string;
    iOS_enterYourExerciseValues: string;
    iOS_dontAskAgain: string;
    iOS_eventDescription: string;
    iOS_updateApp: string;
    iOS_updateAvailable: string;
    iOS_updateTheAppText: string;
    iOS_lastAppBuildNumber: string;
    AND_ACCOUNT_FRAGMENT_DELETE_ACCOUNT: string;
    AND_DELETE_ACCOUNT_DIALOG_TITLE: string;
    AND_DELETE_ACCOUNT_DIALOG_DESCRIPTION: string;
    AND_DELETE_ACCOUNT_DIALOG_DELETE_ACCOUNT: string;
    AND_COMMON_WEEKS: string;
    AND_COMMON_DAYS_WEEK: string;
    AND_COMMON_REPS: string;
    AND_COMMON_WEIGHT_UNIT: string;
    AND_COMMON_MEALS_DAY: string;
    AND_COMMON_SECONDS: string;
    AND_COMMON_WEIGHT_PERC: string;
    AND_WORKOUT_DETAILS_START_SESSION: string;
    AND_WORKOUT_DETAILS_FINISH_SESSION: string;
    AND_WORKOUT_DETAILS_DESCRIPTION: string;
    AND_SUBSCRIBE_ADAPTER_ACTIVE: string;
    AND_SUBSCRIBE_ADAPTER_POPULAR: string;
    AND_HOME_FRAGMENT_TRAINING_TYPE_TITLE: string;
    AND_WORKOUT_MODE_START: string;
    AND_NUTRITION_DETAILS_FRAGMENT_CALENDAR_MEALS: string;
    AND_COMMON_SHOW_MORE: string;
    AND_COMMON_SHOW_LESS: string;
    AND_WORKOUT_PREVIEW_FRAGMENT_WEEK_DAY: string;
    AND_WORKOUT_MODE_CONFIG: string;
    AND_PHOTO_DIALOG_PERMISSION_NEEDED_TEXT: string;
    AND_PHOTO_DIALOG_PERMISSION_NEEDED_MESSAGE: string;
    AND_PHOTO_DIALOG_PERMISSION_POSITIVE_BUTTON_TEXT: string;
    AND_PHOTO_DIALOG_PERMISSION_NEGATIVE_BUTTON_TEXT: string;
    AND_PHOTO_DIALOG_PERMISSION_DENIED_TEXT: string;
    AND_PHOTO_DIALOG_SHOW_NEVER_ASK_TEXT: string;
    iOS_liveEventLeaveFull: string;
    iOS_trainingTypesSectionHeader: string;
    iOS_proMembersExerciseOnlyDescription: string;
    iOS_deleteYourAccount: string;
    iOS_delete: string;
    iOS_pushNotifications: string;
    iOS_areYouSureYouWantToDeleteAccount: string;
    iOS_activationEmailResent: string;
    iOS_activationEmailResentError: string;
    iOS_startSession: string;
    iOS_finishSession: string;
    iOS_workoutMode: string;
    iOS_followYourSchedule: string;
    iOS_followYourScheduleDesc: string;
    iOS_go: string;
    iOS_gotIt: string;
    iOS_swipeRightExplanationText: string;
    iOS_swipeLeftExplanationText: string;
    iOS_swipeBetweenExercises: string;
    iOS_startNextSet: string;
    iOS_nextUp: string;
    AND_EXERCISE_VALUES_EDIT_FRAGMENT_SUBMIT: string;
    AND_WORKOUT_MODE_NEXT_SET_FRAGMENT_SUPERTITLE: string;
    AND_WORKOUT_MODE_NEXT_SET_FRAGMENT_FINISHED: string;
    AND_WORKOUT_MODE_NEXT_SET_FRAGMENT_READY: string;
    AND_WORKOUT_MODE_NEXT_SET_FRAGMENT_SUBMIT: string;
    AND_WORKOUT_MODE_NEXT_SET_FRAGMENT_ENTER: string;
    AND_EXERCISE_EDIT_VALUES_ADAPTER_ERROR: string;
    AND_TAILOR_MADE_DIALOG_OPTION_1: string;
    AND_TAILOR_MADE_DIALOG_OPTION_2: string;
    AND_TAILOR_MADE_DIALOG_TITLE: string;
    AND_TAILOR_MADE_DIALOG_DESCRIPTION: string;
    AND_TAILOR_MADE_PLAN_TITLE: string;
    AND_TAILOR_MADE_PLAN_DESCRIPTION: string;
    AND_WORKOUT_LEVEL_FILTER: string;
    AND_EXERCISE_LEVEL_FILTER: string;
    AND_PROGRAM_LEVEL_FILTER: string;
    AND_LIVE_EVENT_COMMON_SIGNUP: string;
    AND_STREAMING_FRAGMENT_LIVE: string;
    AND_STREAMING_FRAGMENT_LIVE_STREAM: string;
    AND_STREAMING_FRAGMENT_STREAM_FINISHED: string;
    AND_STREAMING_FRAGMENT_BEGIN: string;
    AND_STREAMING_FRAGMENT_THANKS: string;
    AND_HOME_FRAGMENT_INSIGHTS: string;
    AND_BADGE_LIVE: string;
    AND_EXERCISE_VALUES_EDIT_FRAGMENT_PREVIOUS: string;
    AND_WORKOUT_MODE_NEXT_SET_FRAGMENT_FINISH_PREV: string;
    AND_WORKOUT_MODE_NEXT_SET_FRAGMENT_EXERCISES: string;
    AND_WORKOUT_MODE_NEXT_SET_FRAGMENT_ROUNDS: string;
    AND_TAILOR_MADE_DIALOG_DESCRIPTION_TWO: string;
    AND_TAILOR_MADE_DIALOG_ERROR_MESSAGE: string;
    AND_TAILOR_MADE_DIALOG_EMAIL_HINT: string;
    AND_CUSTOM_EXERCISE_DETAILS_DESC: string;
    AND_CUSTOM_EXERCISE_DETAILS_SWIPE: string;
    AND_CUSTOM_EXERCISE_DETAILS_NEXT_UP: string;
    AND_DISCARD_CHANGES_DIALOG_DISCARD_UNSAVED: string;
    AND_DISCARD_CHANGES_DIALOG_LEAVE: string;
    AND_DISCARD_CHANGES_DIALOG_SUBMIT: string;
    AND_WORKOUT_MODE_INSTRUCTION_FRAGMENT_TITLE: string;
    AND_WORKOUT_MODE_INSTRUCTION_FRAGMENT_GOT_IT: string;
    AND_WORKOUT_MODE_NEW_EXERCISE_FRAGMENT_ROUND_OF: string;
    AND_WORKOUT_MODE_NEW_EXERCISE_FRAGMENT_READY: string;
    AND_WORKOUT_MODE_NEW_REST_TIME_FRAGMENT_TITLE: string;
    AND_WORKOUT_MODE_NEW_RESULT_FRAGMENT_TITLE: string;
    AND_WORKOUT_MODE_NEW_RESULT_FRAGMENT_SUBTITLE: string;
    AND_WORKOUT_MODE_NEW_RESULT_FRAGMENT_SUBMIT: string;
    iOS_insightsSectionHeader: string;
    iOS_insightDetails: string;
    iOS_ProgramLevelFilter: string;
    iOS_WorkoutLevelFilter: string;
    iOS_ExerciseLevelFilter: string;
    iOS_tailorMadePlanButton: string;
    iOS_tailorMadePlanPopUpTitle: string;
    iOS_tailorMadePlanPopUpQuestion: string;
    iOS_tailorMadePlanPopUpQuestionMail: string;
    iOS_tailorMadePlanPopUpConfirmation: string;
    iOS_tailorMadeDescription: string;
    iOS_tailorMadeNewMail: string;
    iOS_tailorMadeConfirmMail: string;
    iOS_saveExerciseValues: string;
    iOS_enterExerciseValuesForPreviousSet: string;
    iOS_roundOf: string;
    iOS_swipeToSeeMore: string;
    iOS_endWorkoutQuestionTitle: string;
    iOS_endWorkoutQuestionDescription: string;
    iOS_endWorkout: string;
    iOS_finsihedSetName: string;
    iOS_getReadyForNextSetName: string;
    iOS_nextSetNumberOfRounds: string;
    iOS_nextSetNumberOfExercises: string;
    iOS_liveDetailsSignUpLabel: string;
    iOS_liveEventSignup: string;
    iOS_liveDetailsLeaveLabel: string;
    iOS_finalExercise: string;
    inactive_users_notification_title: string;
    inactive_users_notification_message: string;
    iOS_invalidPin: string;
    iOS_deletePin: string;
    iOS_ComingSoon: string;
    iOS_liveStreamStartTitle: string;
    iOS_liveStreamEndTitle: string;
    iOS_liveStreamStartSubtitle: string;
    iOS_liveStreamEndSubtitle: string;
    iOS_soundOptions: string;
    iOS_turnSoundOn: string;
    iOS_countdownSound: string;
    iOS_beep1: string;
    iOS_beep2: string;
    iOS_beep3: string;
    iOS_none: string;
    iOS_plansSectionHeaderLeanOnCoach: string;
    iOS_workoutsSectionHeaderLeanOnCoach: string;
    iOS_exercisesSectionHeaderLeanOnCoach: string;
    iOS_trainingTypesSectionHeaderLeanOnCoach: string;
    iOS_nutritionProgramsSectionHeaderLeanOnCoach: string;
    iOS_mealsSectionHeaderLeanOnCoach: string;
    iOS_insightsSectionHeaderLeanOnCoaach: string;
    iOS_subscribeToPushNotif: string;
    iOS_planDetails: string;
    iOS_nutritionSegmentTitleLeanOnCoach: string;
    iOS_liveDetailsLabel: string;
    iOS_planLimitExceeded: string;
    iOS_resetPlanProgress_title: string;
    iOS_resetPlanProgress_description: string;
    iOS_resetBtn: string;
    AND_SIGNUP_CHOOSER_FRAGMENT_GOOGLE_FAILED: string;
    AND_SIGN_IN_ACTIVITY_GOOGLE_FAILED: string;
    AND_ACCOUNT_FRAGMENT_SUCCESS_UPDATE_FIRST_NAME: string;
    AND_ACCOUNT_FRAGMENT_SUCCESS_UPDATE_LAST_NAME: string;
    AND_ACCOUNT_FRAGMENT_SUCCESS_UPDATE_UNIT: string;
    AND_PROFILE_SUCCESS_TAILOR: string;
    AND_PROFILE_TAILOR_MADE_VISIBILITY: string;
    AND_PROGRESS_WORKOUTS: string;
    AND_PROGRESS_MEALS: string;
    AND_PROGRAM_DETAILS_SUCCESS_ACTIVATED: string;
    AND_UPGRADE_FRAGMENT_LANDING_PAGE_TOOLBAR_TITLE: string;
    AND_COMMON_SOMETHING_GETS_WRONG: string;
    AND_BILLING_MANAGER_SERVICE_TIMEOUT: string;
    AND_BILLING_MANAGER_FEATURE_NOT_SUPPORTED: string;
    AND_BILLING_MANAGER_SERVICE_DISCONNECTED: string;
    AND_BILLING_MANAGER_USER_CANCELLED: string;
    AND_BILLING_MANAGER_SERVICE_UNAVAILABLE: string;
    AND_BILLING_MANAGER_BILLING_UNAVAILABLE: string;
    AND_BILLING_MANAGER_ITEM_UNAVAILABLE: string;
    AND_BILLING_MANAGER_DEVELOPER_ERROR: string;
    AND_BILLING_MANAGER_ERROR: string;
    AND_BILLING_MANAGER_ITEM_ALREADY_OWNED: string;
    AND_BILLING_MANAGER_ITEM_NOT_OWNED: string;
    AND_CHOOSE_PIN_DIALOG_TITLE: string;
    AND_CHOOSE_PIN_DIALOG_FIELD_REQUIRED: string;
    AND_CHOOSE_PIN_FRAGMENT_ADD_PIN: string;
    AND_CHOOSE_PIN_FRAGMENT_PIN_IN_NOT_FOUND: string;
    AND_PROGRAM_ITEM_FRAGMENT_COMING_SOON: string;
    AND_SOUND_OPTIONS_DIALOG_TITLE: string;
    AND_SOUND_OPTIONS_DIALOG_SWITCH: string;
    AND_SOUND_OPTIONS_DIALOG_CHOOSE_TITLE: string;
    AND_SOUND_OPTIONS_DIALOG_BEEP: string;
    AND_SOUND_OPTIONS_DIALOG_NONE: string;
    iOS_tailorMadeIsHidden: string;
    AND_PROFILE_SUCCESS_TAILOR_NUTRITION: string;
    AND_UPGRADE_FRAGMENT_PRIVACY: string;
    AND_UPGRADE_FRAGMENT_TERMS: string;
    AND_UPGRADE_FRAGMENT_LINKS_TEXT: string;
    AND_UPGRADE_FRAGMENT_AND_TEXT: string;
    AND_SUBSCRIBE_ADAPTER_FREE_TRIAL: string;
    AND_SUBSCRIBE_ADAPTER_DAY: string;
    AND_SUBSCRIBE_ADAPTER_DAYS: string;
    AND_TAILOR_MADE_PROGRAM: string;
    AND_TAILOR_MADE_NUTRITION: string;
    iOS_message_details: string;
    iOS_yourMessage: string;
    iOS_packagesDesription: string;
    iOS_bySubscibingYouAgree: string;
    iOS_freeTrial: string;
    iOS_termsLink: string;
    iOS_privacyLink: string;
    iOS_tailorMadeTitle: string;
    iOS_tailorMadeNutritionButton: string;
    iOS_tailorMadeNutritionPopUpQuestion: string;
    iOS_tailorMadeNutritionPopUpConfirmation: string;
    iOS_tailorMadeVisibility: string;
    iOS_nutriTasksTitle: string;
    iOS_nutriTasksDescription: string;
    iOS_nutritionLinkText: string;
    iOS_nutritionLink: string;
    AND_PROFILE_HEALTH_DATA_TITLE: string;
    AND_PROFILE_HEALTH_DATA_STEPS: string;
    AND_PROFILE_HEALTH_DATA_STEPS_UNIT: string;
    AND_PROFILE_HEALTH_DATA_HEART_RATE: string;
    AND_PROFILE_HEALTH_DATA_HEART_RATE_UNIT: string;
    AND_PROFILE_HEALTH_DATA_ENERGY: string;
    AND_PROFILE_HEALTH_DATA_HEART_ENERGY_UNIT: string;
    AND_PROFILE_HEALTH_DATA_WEIGHT: string;
    AND_PROFILE_HEALTH_DATA_HEIGHT: string;
    AND_SCHEDULING_ENABLED: string;
    AND_PROGRESS_REQUEST_EVENT: string;
    AND_PROGRESS_EVENTS: string;
    AND_MEAL_DETAILS_ACTIVITY_NUTRITION_TEXT: string;
    AND_MEAL_DETAILS_ACTIVITY_NUTRITION_LINK_TEXT: string;
    AND_DIALOG_NO: string;
    AND_MESSAGING_ENABLED: string;
    AND_CHAT_TODAY: string;
    AND_CHAT_HINT: string;
    AND_CHAT_TITLE: string;
    AND_CHAT_CHOOSE_FILE: string;
    AND_CHAT_DELETE_SUCCESS: string;
    AND_CHAT_PERMISSION_NEEDED_SAVE_FILES_MESSAGE: string;
    AND_SCHEDULE_FRAGMENT_REQUEST_EVENT: string;
    AND_SCHEDULE_NOTIFICATIONS_FRAGMENT_TITLE: string;
    AND_SCHEDULE_ACTION_DIALOG_CANCEL_TITLE: string;
    AND_SCHEDULE_ACTION_DIALOG_CANCEL_DESCRIPTION: string;
    AND_SCHEDULE_ACTION_DIALOG_CANCEL_SUBMIT: string;
    AND_SCHEDULE_ACTION_DIALOG_DECLINE_EVENT_TITLE: string;
    AND_SCHEDULE_ACTION_DIALOG_DECLINE_EVENT_DESCRIPTION: string;
    AND_SCHEDULE_ACTION_DIALOG_DECLINE_EVENT_SUBMIT: string;
    AND_SCHEDULE_ACTION_DIALOG_DECLINE_NEW_TIME_TITLE: string;
    AND_SCHEDULE_ACTION_DIALOG_DECLINE_NEW_TIME_DESCRIPTION: string;
    AND_SCHEDULE_ACTION_DIALOG_DECLINE_NEW_TIME_SUBMIT: string;
    AND_SCHEDULE_DETAILS_FRAGMENT_TITLE: string;
    AND_SCHEDULE_DETAILS_FRAGMENT_NEW_TIME_BY_YOU: string;
    AND_SCHEDULE_DETAILS_FRAGMENT_ACCEPT_BTN_EVENT: string;
    AND_SCHEDULE_DETAILS_FRAGMENT_DECLINE_BTN_EVENT: string;
    AND_SCHEDULE_DETAILS_FRAGMENT_SUGGEST: string;
    AND_SCHEDULE_DETAILS_FRAGMENT_ACCEPT_BTN_NEW_TIME: string;
    AND_SCHEDULE_DETAILS_FRAGMENT_DECLINE_BTN_NEW_TIME: string;
    AND_SCHEDULE_DETAILS_FRAGMENT_NEW_TIME_BY_COACH: string;
    AND_SCHEDULE_DETAILS_FRAGMENT_EVENT_STATUS: string;
    AND_SCHEDULE_DETAILS_FRAGMENT_EVENT_TYPE: string;
    AND_SCHEDULE_DETAILS_FRAGMENT_DATE: string;
    AND_SCHEDULE_DETAILS_FRAGMENT_DURATION: string;
    AND_SCHEDULE_DETAILS_FRAGMENT_DESCRIPTION: string;
    AND_SCHEDULE_DETAILS_FRAGMENT_CANCELLED_EVENT: string;
    AND_SCHEDULE_DETAILS_FRAGMENT_ACCEPTED_EVENT: string;
    AND_SCHEDULE_DETAILS_FRAGMENT_ACCEPTED_NEW_TIME: string;
    AND_SCHEDULE_DETAILS_FRAGMENT_DECLINED_EVENT: string;
    AND_SCHEDULE_DETAILS_FRAGMENT_DECLINED_NEW_TIME: string;
    AND_SCHEDULE_DETAILS_FRAGMENT_NO_EVENT_TITLE: string;
    AND_SCHEDULE_DETAILS_FRAGMENT_NO_EVENT_DESCRIPTION: string;
    AND_SCHEDULE_DETAILS_FRAGMENT_NO_EVENT_BUTTON: string;
    AND_SCHEDULE_DETAILS_FRAGMENT_NO_EVENT_CANCELED: string;
    AND_SCHEDULE_DETAILS_FRAGMENT_NO_EVENT_DECLINED: string;
    AND_SCHEDULE_EVENT_STATUS_REQUESTED: string;
    AND_SCHEDULE_EVENT_STATUS_ACCEPTED: string;
    AND_SCHEDULE_EVENT_STATUS_ACCEPTED_EVENT: string;
    AND_SCHEDULE_EVENT_STATUS_REQUESTED_YOU: string;
    AND_SCHEDULE_EVENT_STATUS_REQUESTED_COACH: string;
    AND_SCHEDULE_NOTIFICATION_STATUS_EVENT_REQUEST: string;
    AND_SCHEDULE_NOTIFICATION_STATUS_EVENT_DECLINED: string;
    AND_SCHEDULE_NOTIFICATION_STATUS_UPDATED: string;
    AND_SCHEDULE_NOTIFICATION_STATUS_UPDATE_DECLINED: string;
    AND_SCHEDULE_NOTIFICATION_STATUS_NEW_TIME_SUGGESTED: string;
    AND_SCHEDULE_NOTIFICATION_STATUS_NEW_TIME_DECLINED: string;
    AND_SCHEDULE_NOTIFICATION_STATUS_EVENT_CANCELED: string;
    AND_SCHEDULE_NOTIFICATION_STATUS_SCHEDULED_NEW_EVENT: string;
    AND_SCHEDULE_NOTIFICATION_STATUS_ACCEPTED_EVENT: string;
    AND_SCHEDULE_NOTIFICATION_STATUS_DECLINED_EVENT: string;
    AND_SCHEDULE_NOTIFICATION_STATUS_CANCELED_EVENT: string;
    AND_SCHEDULE_NOTIFICATION_STATUS_SUGGESTED_UPDATE: string;
    AND_SCHEDULE_NOTIFICATION_STATUS_ACCEPTED_UPDATE: string;
    AND_SCHEDULE_NOTIFICATION_STATUS_DECLINED_UPDATE: string;
    AND_SCHEDULE_NOTIFICATION_STATUS_SUGGESTED_NEW_TIME: string;
    AND_SCHEDULE_NOTIFICATION_STATUS_ACCEPTED_NEW_TIME: string;
    AND_SCHEDULE_NOTIFICATION_STATUS_DECLINED_NEW_TIME: string;
    AND_SCHEDULE_NOTIFICATION_STATUS_COACH: string;
    AND_SCHEDULE_EDITOR_FRAGMENT_TYPE_LABEL: string;
    AND_SCHEDULE_EDITOR_FRAGMENT_DESCRIPTION_LABEL: string;
    AND_SCHEDULE_EDITOR_FRAGMENT_DESCRIPTION_HINT: string;
    AND_SCHEDULE_EDITOR_FRAGMENT_DATE_LABEL: string;
    AND_SCHEDULE_EDITOR_FRAGMENT_DATE_HINT: string;
    AND_SCHEDULE_EDITOR_FRAGMENT_START_TIME_LABEL: string;
    AND_SCHEDULE_EDITOR_FRAGMENT_SAVE_BUTTON: string;
    AND_SCHEDULE_EDITOR_FRAGMENT_REQUEST_BUTTON: string;
    AND_SCHEDULE_EDITOR_FRAGMENT_NEW_EVENT_TITLE: string;
    AND_SCHEDULE_EDITOR_FRAGMENT_EDIT_TITLE: string;
    AND_SCHEDULE_EDITOR_FRAGMENT_NEW_TIME_TITLE: string;
    AND_SCHEDULE_EDITOR_FRAGMENT_NEW_EVENT_INFO_TITLE: string;
    AND_SCHEDULE_EDITOR_FRAGMENT_EDIT_INFO_DESCRIPTION: string;
    AND_SCHEDULE_EDITOR_FRAGMENT_NEW_TIME_INFO_TITLE: string;
    AND_SCHEDULE_EDITOR_FRAGMENT_INFO_BUTTON: string;
    AND_NO_EVENTS_DIALOG_TITLE: string;
    AND_NO_EVENTS_DIALOG_DESCRIPTION: string;
    round_item_name: string;
    AND_FACEBOOK_LOGIN_ENABLED: string;
    iOS_calendarTitleLabel: string;
    iOS_requestNewEvent: string;
    iOS_eventsLabel: string;
    iOS_completedActivities: string;
    iOS_requested: string;
    iOS_accepted: string;
    iOS_waitingYourApproval: string;
    iOS_waitingCoachAppoval: string;
    iOS_acceptedEvent: string;
    iOS_declinedEvent: string;
    iOS_suggestNewTime: string;
    iOS_newEventRequestTitle: string;
    iOS_newEventRequestSubtitle: string;
    iOS_newAcceptedTitle: string;
    iOS_newAcceptedSubtitle: string;
    iOS_newCoachDeclinedTitle: string;
    iOS_newCoachDeclinedSubtitle: string;
    iOS_coachDeclinedTitle: string;
    iOS_coachDeclinedSubtitle: string;
    iOS_coachUpdatedTitle: string;
    iOS_coachUpdatedSubtitle: string;
    iOS_updateAcceptedTitle: string;
    iOS_updateAcceptedSubtitle: string;
    iOS_updateCoachDeclinedTitle: string;
    iOS_updateCoachDeclinedSubtitle: string;
    iOS_coachSuggestedNewTimeTitle: string;
    iOS_coachSuggestedNewTimeSubtitle: string;
    iOS_newTimeAcceptedTitle: string;
    iOS_newTimeAcceptedSubtitle: string;
    iOS_newTimeDeclinedTitle: string;
    iOS_newTimeDeclinedSubtitle: string;
    iOS_saveChangesBtn: string;
    iOS_dateInPast: string;
    iOS_chooseDateFirst: string;
    iOS_eventTypeLabel: string;
    iOS_typesDefinedByCoach: string;
    iOS_noAvailableTerms: string;
    iOS_successfullyEdited: string;
    iOS_eventDeclined: string;
    iOS_eventDeclinedExplanation: string;
    iOS_eventCanceled: string;
    iOS_eventCanceledExplanation: string;
    iOS_you: string;
    iOS_coach: string;
    iOS_notifications: string;
    iOS_noFreeTerms: string;
    iOS_requestNewEventPageTitle: string;
    iOS_editEventPageTitle: string;
    iOS_newEventDescriptionPlaceholder: string;
    iOS_eventDetailsPageTitle: string;
    iOS_acceptEvent: string;
    iOS_acceptNewTime: string;
    iOS_declineNewTime: string;
    iOS_declineEvent: string;
    iOS_mySchedule: string;
    iOS_requestNewEventBtn: string;
    iOS_noEventsOrActivities: string;
    iOS_noEventsOrActivitiesDesc: string;
    iOS_noFreeSlots: string;
    iOS_noEventsOrActivitiesPopUp: string;
    iOS_eventRequestedTitle: string;
    iOS_eventRequestedDescription: string;
    iOS_newTimeSuggestedTitle: string;
    iOS_newTimeSuggestedDesc: string;
    iOS_gotItButton: string;
    iOS_declineEventTitle: string;
    iOS_declineEventDesc: string;
    iOS_declineNewTimeTitle: string;
    iOS_declineNewTimeDesc: string;
    iOS_desclineNewTimeSubmit: string;
    iOS_declineEventSubmit: string;
    iOS_cancelEventTitle: string;
    iOS_cancelEventDesc: string;
    iOS_cancelEventSubmit: string;
    iOS_successCancelMessage: string;
    iOS_successAcceptedMessage: string;
    iOS_successDeclinedMessage: string;
    iOS_chooseAvailableDate: string;
    iOS_eventStatusSectionName: string;
    iOS_eventTypeSectionName: string;
    iOS_eventDateTimeSectionName: string;
    iOS_eventOriginalDateTimeSectionName: string;
    iOS_eventNewDateTimeSuggested: string;
    iOS_eventDurationSectionName: string;
    iOS_eventDescriptionSectionName: string;
    iOS_eventMandatoryFields: string;
    iOS_schedulingEnabled: string;
    iOS_messagingEnabled: string;
    iOS_sortOfMealOptions: string;
    iOS_nutriTasks_shakeOptions: string;
    iOS_saveExerciseValues_Done: string;
    iOS_chat_attachment_dialog_title: string;
    iOS_chat_attachment_dialog_image: string;
    iOS_chat_attachment_dialog_document: string;
    iOS_delete_attachment_title: string;
    iOS_delete_attachment_description: string;
    iOS_delete_attachment_button: string;
    AND_USER_HEALTH_TRACKING_ENABLED: string;
    eat_translate_key: string;
    day_translate_key: string;
    week_translate_key: string;
    until_translate_key: string;
    between_translate_key: string;
    options_translate_key: string;
    AND_SIGNUP_CHOOSER_FRAGMENT_FACEBOOK: string;
    AND_SIGNUP_CHOOSER_FRAGMENT_FACEBOOK_CANCEL: string;
    AND_SIGNUP_CHOOSER_FRAGMENT_FACEBOOK_FAILED: string;
    AND_ACTIVATED_NUTRITION_PROGRAM_ITEM_WEEK: string;
    AND_CHAT_FILE_TOO_LARGE: string;
    AND_CHAT_DOWNLOAD_COMPLETED: string;
    AND_CHAT_UPLOAD_ATTACHMENT_TITLE: string;
    AND_CHAT_UPLOAD_ATTACHMENT_DESCRIPTION: string;
    AND_CHAT_UPLOAD_ATTACHMENT_SUBMIT: string;
    AND_CHAT_DELETE_ATTACHMENT_TITLE: string;
    AND_CHAT_DELETE_ATTACHMENT_DESCRIPTION: string;
    AND_CHAT_DELETE_ATTACHMENT_SUBMIT: string;
    AND_SCHEDULED_EVENTS_MINUTES: string;
    AND_SCHEDULE_FRAGMENT_COMPLETED_ACTIVITIES: string;
    AND_SCHEDULE_FRAGMENT_NO_EVENTS_TITLE: string;
    AND_SCHEDULE_FRAGMENT_NO_EVENTS_DESCRIPTION: string;
    AND_SCHEDULE_FRAGMENT_EVENTS_TITLE: string;
    AND_SCHEDULE_NOTIFICATION_STATUS_EVENT_ACCEPTED: string;
    AND_SCHEDULE_NOTIFICATION_STATUS_UPDATE_ACCEPTED: string;
    AND_SCHEDULE_NOTIFICATION_STATUS_NEW_TIME_ACCEPTED: string;
    AND_SCHEDULE_EDITOR_FRAGMENT_TYPE_DESCRIPTION: string;
    AND_SCHEDULE_EDITOR_FRAGMENT_NEW_EVENT_INFO_DESCRIPTION: string;
    AND_SCHEDULE_EDITOR_FRAGMENT_NEW_TIME_INFO_DESCRIPTION: string;
    AND_FITNESS_ACCOUNT_REVOKE: string;
    AND_FITNESS_ACCOUNT_SIGN_IN_GOOGLE: string;
    AND_FITNESS_ACCOUNT_TOOLBAR_TITLE: string;
    AND_FITNESS_ACCOUNT_DATA_FAILURE: string;
    AND_FITNESS_ACCOUNT_LAST_SYNCED: string;
    AND_FITNESS_ACCOUNT_ALREADY_SYNCED: string;
    AND_FITNESS_ACCOUNT_LINKED_USERNAME: string;
    AND_REVOKE_FITNESS_DIALOG_HEADER_TITLE: string;
    AND_REVOKE_FITNESS_DIALOG_CONTENT: string;
    AND_REVOKE_FITNESS_DIALOG_SUBMIT_TEXT: string;
    AND_WORKOUT_PREVIEW_FRAGMENT_MARK_ALL_COMPLETE: string;
    AND_MARK_ALL_COMPLETE_ENABLED: string;
    AND_MARK_ALL_COMPLETE_DIALOG_TITLE: string;
    AND_MARK_ALL_COMPLETE_DIALOG_SUBTITLE: string;
    AND_MARK_ALL_COMPLETE_DIALOG_SUBMIT: string;
    AND_PAYMENT_UPGRADE_ENABLED: string;
    iOS_goToHome: string;
    iOS_mark_all_complete_enabled: string;
    iOS_mark_all_complete_btn_title: string;
    iOS_markAllQuestionTitle: string;
    iOS_markAllQuestionDescription: string;
    AND_PRO_BADGE_ENABLED: string;
    AND_UPGRADE_NOTIFICATION_FRAGMENT_GO_TO_HOME_SUBMIT: string;
    iOS_userHealthTrackingEnabled: string;
    iOS_facebookLoginEnabled: string;
    iOS_pro_badge_enabled: string;
    iOS_maxEditValuesMetric: string;
    iOS_maxEditValuesImperial: string;
}

@Injectable({
    providedIn: 'root'
})
export class TranslateService {
    constructor(private injector: Injector) { }

    public keys: Translate;

    public async init() {
        const data = await this.http.get<Translate>('white-label/translate');
        this.keys = data.data;
        console.log(this.keys)
    }

    private get http() {
        return this.injector.get(FitApiClientService);
    }
}
