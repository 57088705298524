import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({ providedIn: 'root' })
export class DateTimeFormatHelper {
    get dateFormat() {
        return environment.format.date;
    }
    get timeFormat() {
        return environment.format.time;
    }
    get dateTimeFormat() {
        return environment.format.dateTime;
    }
}

