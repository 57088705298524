import { ElementRef } from '@angular/core';
import { Directive } from '@angular/core';

const Navigator: any = {
    userAgentData: navigator['userAgentData'] || navigator['platform']
}

@Directive({
    selector: '[checkPlatform]'
})
export class PlatformDirective {


    constructor(el: ElementRef) {
        if (this.checkPlatform()) {
            el.nativeElement.classList.add('ios-platform');
        }
    }

    private checkPlatform() {
        return [
            'iPad Simulator',
            'iPhone Simulator',
            'iPod Simulator',
            'iPad',
            'iPhone',
            'iPod'
        ].includes(Navigator.userAgentData.platform || Navigator.userAgentData);
    }


}
