import { APP_INITIALIZER, ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BeforeInstallPrompt } from './custom-ui-pwa-install.providers';
import { CustomUIPWAInstallComponent } from './custom-ui-pwa-install.component';

export function beforeInstallPromptInitializerFactory(beforeInstallPrompt) {
    const initializer = () => Promise.resolve(beforeInstallPrompt);
    return initializer;
}

@NgModule({
    declarations: [CustomUIPWAInstallComponent],
    imports: [
        CommonModule
    ],
    exports: [CustomUIPWAInstallComponent]
})
export class CustomUIPWAInstallModule {
    static forRoot(): ModuleWithProviders<CustomUIPWAInstallModule> {

        return {
            ngModule: CustomUIPWAInstallModule,
            providers: [
                {
                    provide: APP_INITIALIZER,
                    useFactory: beforeInstallPromptInitializerFactory,
                    deps: [BeforeInstallPrompt],
                    multi: true
                }
            ]
        };
    }
}
