import { Router } from '@angular/router';
import { AuthService } from '../services';

export function appInitializer(authService: AuthService, router: Router) {
    return () => new Promise(async (resolve: any) => {

        if (authService.isAuthenticated()) {
            authService.startRefreshTokenTimer();
            await authService.getUserData();
            router.navigate(['/home']);
        } else {
            const success = await authService.refreshToken();
            if (success) {
                await authService.getUserData();
                router.navigate(['/home']);
            } else {
                router.navigate(['/']);
            }
        }
        resolve();
    });
}
