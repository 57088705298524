import { APP_INITIALIZER, Injectable, NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule, HammerGestureConfig, HammerModule, HAMMER_GESTURE_CONFIG } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { ToastrModule } from 'ngx-toastr';
import { HttpClientModule, HTTP_INTERCEPTORS, HttpClient } from '@angular/common/http';
import { JwtModule } from '@auth0/angular-jwt';
import { tokenGetter, TokenStorageService } from './shared/services/token-storage.service';
import { appInitializer, appPwaSettingsInitializer } from './shared/helpers';
import { AuthService } from './shared/services';
import { Router } from '@angular/router';
import { JwtInterceptor, OfflineInterceptor } from './shared/interceptors';
import { FacebookLoginProvider, SocialLoginModule, SocialAuthServiceConfig } from 'angularx-social-login';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { CookieService } from 'ngx-cookie-service';
import * as Hammer from 'hammerjs';
import { TranslateService } from './shared/services/translate.service';
import { appTranslateInitializer } from './shared/helpers/app.translate.initializer';
import { appStyleInitializer } from './shared/helpers/app.style.initializer';
import { CustomUIPWAInstallModule } from './shared/components/custom-ui-pwa-install/custom-ui-pwa-install.module';
import { DirectivesModule } from './shared/directives/directives.module';

@Injectable()
export class MyHammerConfig extends HammerGestureConfig {
    override = <any>{
        'swipe': { direction: Hammer.DIRECTION_ALL }
    }

}

@NgModule({
    declarations: [AppComponent],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        AppRoutingModule,
        HttpClientModule,
        SocialLoginModule,
        HammerModule,
        DirectivesModule,
        CustomUIPWAInstallModule,
        AngularSvgIconModule.forRoot(),
        ToastrModule.forRoot({
            iconClasses: {
                error: 'toast-custom-error',
                info: 'toast-custom-info',
                success: 'toast-custom-success',
                warning: 'toast-custom-warning'
            }
        }),
        JwtModule.forRoot({
            config: {
                tokenGetter,
                allowedDomains: [
                    'localhost',
                    'fitness-marketplace.test:8083',
                    'fitin-api.telego-dev.net',
                    'api.leanondigital.com'
                ],
            }
        }),

        ServiceWorkerModule.register('ngsw-worker.js', {
            enabled: true,
            // Register the ServiceWorker as soon as the app is stable
            // or after 30 seconds (whichever comes first).
            registrationStrategy: 'registerWhenStable:30000'
        })
    ],
    providers: [
        // { provide: APP_INITIALIZER, useFactory: appPwaSettingsInitializer, multi: true, deps: [HttpClient, CookieService] },
        { provide: APP_INITIALIZER, useFactory: appStyleInitializer, multi: true, deps: [CookieService, TokenStorageService] },
        { provide: APP_INITIALIZER, useFactory: appInitializer, multi: true, deps: [AuthService, Router] },
        { provide: APP_INITIALIZER, useFactory: appTranslateInitializer, multi: true, deps: [TranslateService] },
        { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: OfflineInterceptor, multi: true },
        {
            provide: HAMMER_GESTURE_CONFIG,
            useClass: HammerGestureConfig,
        },
        {
            provide: 'SocialAuthServiceConfig',
            useValue: {
                autoLogin: false,
                providers: [
                    {
                        id: FacebookLoginProvider.PROVIDER_ID,
                        provider: new FacebookLoginProvider(
                            '307308077471415'
                        )
                    }
                ]
            } as SocialAuthServiceConfig,
        },
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
