<ng-container *ngIf="!isAuthenticated">
    <router-outlet></router-outlet>
</ng-container>
<div *ngIf="isAuthenticated"
     checkPlatform
     class="app-container with-color-bg mx-auto"
     [class.hide-navigation]="hidden && hasBottomAction === false">
    <router-outlet></router-outlet>
    <div *ngIf="!hidden"
         class="bottom-navigation mx-auto">
        <div class="nav-item">
            <a [routerLink]="'/home'"
               routerLinkActive="active"
               #isActiveHome="routerLinkActive">
                <svg-icon *ngIf="!isActiveHome.isActive"
                          [src]="assetsUrlService.getAssetsUrl('icons/ic_home_inactive.svg')"></svg-icon>
                <svg-icon *ngIf="isActiveHome.isActive"
                          [src]="assetsUrlService.getAssetsUrl('icons/ic_home_active.svg')"></svg-icon>
                <p>{{translate.keys.AND_MAIN_ACTIVITY_HOME}}</p>
            </a>
        </div>
        <div class="nav-item"
             *ngIf="isFeedAvailable">
            <a [routerLink]="'/feed'"
               routerLinkActive="active"
               #isActiveFeed="routerLinkActive">
                <svg-icon *ngIf="!isActiveFeed.isActive"
                          [src]="assetsUrlService.getAssetsUrl('icons/ic_feed_inactive.svg')"></svg-icon>
                <svg-icon *ngIf="isActiveFeed.isActive"
                          [src]="assetsUrlService.getAssetsUrl('icons/ic_feed_active.svg')"></svg-icon>
                <p>{{translate.keys.AND_MAIN_ACTIVITY_FEED}}</p>
            </a>
        </div>
        <div class="nav-item">
            <a [routerLink]="'/plans'"
               routerLinkActive="active"
               #isActivePlans="routerLinkActive">
                <svg-icon *ngIf="!isActivePlans.isActive"
                          [src]="assetsUrlService.getAssetsUrl('icons/ic_plans_inactive.svg')"></svg-icon>
                <svg-icon *ngIf="isActivePlans.isActive"
                          [src]="assetsUrlService.getAssetsUrl('icons/ic_plans_active.svg')"></svg-icon>
                <p>{{translate.keys.AND_MAIN_ACTIVITY_PROGRAMS}}</p>
            </a>
        </div>
        <!-- <div class="nav-item">
            <a [routerLink]="'/progress'"
               routerLinkActive="active"
               #isActiveProgress="routerLinkActive">
                <svg-icon *ngIf="!isActiveProgress.isActive"
                          [src]="assetsUrlService.getAssetsUrl('icons/ic_progress_inactive.svg')"></svg-icon>
                <svg-icon *ngIf="isActiveProgress.isActive"
                          [src]="assetsUrlService.getAssetsUrl('icons/ic_progress_active.svg')"></svg-icon>
                <p>{{translate.keys.AND_MAIN_ACTIVITY_PROGRESS}}</p>
            </a>
        </div> -->
        <div class="nav-item">
            <a [routerLink]="'/profile'"
               routerLinkActive="active"
               #isActiveProfile="routerLinkActive">
                <svg-icon *ngIf="!isActiveProfile.isActive"
                          [src]="assetsUrlService.getAssetsUrl('icons/ic_profile_inactive.svg')"></svg-icon>
                <svg-icon *ngIf="isActiveProfile.isActive"
                          [src]="assetsUrlService.getAssetsUrl('icons/ic_profile_active.svg')"></svg-icon>
                <p>{{translate.keys.AND_MAIN_ACTIVITY_PROFILE}}</p>
            </a>
        </div>
    </div>
</div>

<custom-ui-pwa-install #pwa>
    <div class="custom-ui-pwa-install">
        <div class="custom-ui-pwa-install-container">
            <img [src]="assetsUrlService.getAssetsUrl('app_icons/icon-72x72.png')" />
            <p>This is a preview of your branded <br /> app with a demo content.</p>
            <button type="button"
                    class="button primary with-icon"
                    (click)="pwa.install()">
                <svg-icon [src]="assetsUrlService.getAssetsUrl('icons/ic_download.svg')"></svg-icon>
                Install app
            </button>

        </div>
    </div>
</custom-ui-pwa-install>

<div *ngIf="showPWAInstallOnApple"
     class="custom-ui-pwa-install">
    <div class="custom-ui-pwa-install-container ios">
        <img [src]="assetsUrlService.getAssetsUrl('app_icons/icon-72x72.png')" />
        <ng-container *ngIf="!showInstallDesc">
            <p>This is a preview of your branded <br /> app with a demo content.</p>
            <button type="button"
                    class="button primary with-icon"
                    (click)="showInstallDesc = true;">
                <svg-icon [src]="assetsUrlService.getAssetsUrl('icons/ic_download.svg')"></svg-icon>
                Install app
            </button>
        </ng-container>
        <ng-container *ngIf="showInstallDesc">
            <h4>Install app</h4>
            <p>Supported only on <b>iOS Safari</b> <br /><br />Install this application on your home <br /> screen for quick and easy access.</p>
            <p class="mb-0 smaller add-home-screen">
                Tap <svg-icon [src]="assetsUrlService.getAssetsUrl('icons/ic_download_ios.svg')"></svg-icon> then "Add
                to
                Home Screen"
            </p>
        </ng-container>
    </div>
</div>
