import { Inject, Injectable, Injector } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, firstValueFrom } from 'rxjs';

import { NotificationsService } from './notifications.service';
import { TokenStorageService } from './token-storage.service';
import { JwtHelperService } from '@auth0/angular-jwt';
import { environment } from 'src/environments/environment';
import { mapAsyncError } from '../helpers/http-response-mapping';
import { ApiResponse } from '../types';

interface TokenResponse {
    token_type: string;
    expires_in: number;
    access_token: string;
    refresh_token: string;
}

interface Achievement {
    title: string;
    description: string;
    image_url: string;
    is_unlocked: boolean;
}

interface ActivatedProgram {
    id: number;
    image_url: string;
    is_tailor_made: boolean;
    name: string;
    percentage_completed: number;
}

interface HealthData { heart_rate: number; height: number; weight: number; energy_consumed: number; steps: number; }

interface Streak { current_daily_streak: number; longest_daily_streak: number; current_weakly_streak: number; longest_weekly_streak: number; }

export interface UserProfile {
    achievements: Achievement[];
    activated_programs: ActivatedProgram[];
    cover_image_url: string;
    daily_streak: number;
    first_name: string;
    health_data: HealthData;
    id: number;
    image_url: string;
    last_name: string;
    programs_completed_count: number;
    streaks: Streak;
    trainings_completed_count: number;
}

export interface User {
    email: string;
    first_name: string;
    has_seen_welcome_message: boolean;
    id: number;
    last_name: string;
    metric_unit: string;
    upload_limit_in_bytes: number;
}

interface UserBasicData {
    email: string;
    firebase_id: string;
    first_name: string;
    has_seen_welcome_message: boolean;
    health_data: HealthData;
    id: number;
    last_name: string;
    metric_unit: string;
    need_to_pay_to_play: boolean;
    recent_fitness_timestamp: string;
    show_feed_button: boolean;
    timezone: string;
    upload_limit_in_bytes: number;
    welcome_video_url: string;
}

export interface UserData {
    user: User;
    profile: UserProfile;
    basic: UserBasicData;
}

@Injectable({ providedIn: 'root' })
export class AuthService {
    constructor(
        private http: HttpClient,
        private jwtHelper: JwtHelperService,
        private tokenStorageService: TokenStorageService,
        @Inject(Injector) private readonly injector: Injector
    ) {
        this.userSubject = new BehaviorSubject<any>(null);
    }

    public get userDataValue() {
        return this.userSubject;
    }

    public async login(username: string, password: string) {
        const grantType = 'password';
        const clientId = environment.client_id;
        const clientSecret = environment.client_secret;
        const { data, error } = await this.post<TokenResponse>(`oauth/token`, {
            grant_type: grantType, client_id: clientId, client_secret: clientSecret, username, password
        });
        if (!error) {
            this.tokenStorageService.saveRefreshToken(data.refresh_token);
            this.tokenStorageService.saveToken(data.access_token);
            this.getUserData();
            this.startRefreshTokenTimer();
            localStorage.removeItem('google_client_id');
        }
        return { data, error };
    }

    public async loginSocial(accessToken: string, provider: string) {
        const grantType = 'social';
        const clientId = environment.client_id;
        const clientSecret = environment.client_secret;
        const { data, error } = await this.post<TokenResponse>(`oauth/token`, {
            grant_type: grantType, client_id: clientId, client_secret: clientSecret, access_token: accessToken, provider
        });
        if (!error) {
            this.tokenStorageService.saveRefreshToken(data.refresh_token);
            this.tokenStorageService.saveToken(data.access_token);
            this.getUserData();
            this.startRefreshTokenTimer();
            localStorage.removeItem('google_client_id');
        }
        return { data, error };
    }

    public async register(name: string, surname: string, email: string, password: string) {
        localStorage.removeItem('google_client_id');
        return this.post<any>(`white-label/user/register`, { first_name: name, last_name: surname, email, password }).then(user => user);
    }

    public async logout() {
        this.stopRefreshTokenTimer();
        this.tokenStorageService.logout();
        this.userSubject.next(null);
        window.location.reload();
    }

    public async refreshToken() {
        const refreshToken = this.tokenStorageService.getRefreshToken();
        if (!refreshToken) {
            return false;
        }
        const grantType = 'refresh_token';
        const clientId = environment.client_id;
        const clientSecret = environment.client_secret;
        const { data, error } = await this.post<TokenResponse>(`oauth/token`, {
            grant_type: grantType, client_id: clientId, client_secret: clientSecret, refresh_token: refreshToken
        });

        if (!error) {
            this.tokenStorageService.saveRefreshToken(data.refresh_token);
            this.tokenStorageService.saveToken(data.access_token);
            this.startRefreshTokenTimer();
            return true;
        } else {
            this.logout();
            return false;
        }
    }

    public resetPassword(email: string) {
        return this.post<any>(`forgotten-password/send-otp`, { destination: email });
    }


    public saveNewPassword(code: string, destination: string, password: string) {
        return this.post<any>(`forgotten-password/change-password`, { destination, password, code });
    }

    public isAuthenticated(): boolean {
        return !this.jwtHelper.isTokenExpired();
    }

    public startRefreshTokenTimer() {
        const timeout = this.jwtHelper.getTokenExpirationDate().getTime() - (new Date().getTime() + (60 * 1000));
        this._refreshTokenTimeout = setTimeout(async () => await this.refreshToken(), timeout);
    }

    public async getUserData() {
        const [
            userProfileResult,
            userResult,
            userBasicDataResult
        ] = await Promise.all([
            this.get<UserProfile>('white-label/user/profile'),
            this.get<User>('white-label/user'),
            this.get<UserBasicData>('white-label/user/basic')
        ]);

        if (userProfileResult.error || userResult.error || userBasicDataResult.error) {
            return null;
        }

        const userData: UserData = {
            profile: userProfileResult.data,
            user: userResult.data,
            basic: userBasicDataResult.data
        };

        this.userSubject.next(userData);

        return userData;
    }

    private stopRefreshTokenTimer() {
        clearTimeout(this._refreshTokenTimeout);
    }

    private async post<T>(path: string, body) {
        const url = this.createUrl(path);
        const headers = this.getPin();
        const { data, error } = await mapAsyncError<T>(firstValueFrom(this.http.post<ApiResponse<T>>(url, body, { headers })));
        if (error) {
            this.notifications.error('Error', error.errorMessages.join('\r\n'));
        }
        return { data, error };
    }

    private async get<T>(path: string) {
        const url = this.createUrl(path);
        const headers = this.getPin();
        const { data, error } = await mapAsyncError<T>(firstValueFrom(this.http.get<ApiResponse<T>>(url, { headers })));
        if (error) {
            this.notifications.error('Error', error.errorMessages.join('\r\n'));
        }
        return { data, error };
    }

    private get notifications(): NotificationsService {
        return this.injector.get(NotificationsService);
    }

    private createUrl(path: string) {
        return environment.apiUrl + path;
    }

    private getPin() {
        return !!this.tokenStorageService.getPin() ? new HttpHeaders({ 'x-white-label-application-pin': this.tokenStorageService.getPin() }) : new HttpHeaders({ 'x-white-label-application-pin': '4o2e34' });
    }

    private _refreshTokenTimeout: any;
    private userSubject: BehaviorSubject<UserData>;
}
