import { ApiResponse } from "../types";
import { HttpErrorResponse } from '@angular/common/http';

export function isApiResponse<T>(object: any): object is ApiResponse<T> {
    return !!object && 'success' in object && 'errorCode' in object && 'errorMessages' in object;
}


export function mapAsyncError<T>(promise: Promise<ApiResponse<T>>): Promise<{ data: T, error: ApiResponse<any> }> {
    return promise.then(data => {
        if ((data as any).token_type) {
            return {
                data: data as any as T,
                error: undefined
            };
        }

        if (!data.success) {
            throw data;
        }
        return {
            data: data.data,
            error: undefined
        };
    }).catch((error: HttpErrorResponse | ApiResponse<T>) => {
        if (!isApiResponse(error)) {
            if (isApiResponse(error.error)) {
                return {
                    data: undefined,
                    error: error.error
                };
            }
            return {
                data: undefined,
                error: {
                    success: false,
                    errorCode: error.status,
                    errorMessages: [error.message],
                    data: null
                }
            };
        } else {
            return {
                data: undefined,
                error
            };
        }
    });
}
