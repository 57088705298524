import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { environment } from '../../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class AssetsUrlService {

    constructor(cookieService: CookieService) {
        this._pinValue = cookieService.get('pin');
    }

    public getAssetsUrl(fileUrl: string) {
        if (this._pinValue) {
            return `${environment.awsS3BucketUrl}/pwa/${this._pinValue}/assets/${fileUrl}`;
        } else {
            return `assets/${fileUrl}`;
        }
    }

    private _pinValue = '';
}
