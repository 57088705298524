import { Injectable } from '@angular/core';
import { FitApiClientService } from './fit-api-client.service';

export interface LevelFilter {
    id: number;
    name: string;
    checked: boolean;
}

@Injectable({ providedIn: 'root' })
export class HelperService {
    constructor(private apiService: FitApiClientService) { }

    public getPlanFilters() {
        return this.apiService.get<LevelFilter[]>(`white-label/filter/program`);
    }
}
