import { mapAsyncError } from './http-response-mapping';
import { firstValueFrom } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { ApiResponse } from '../types';
import { environment } from 'src/environments/environment';
import { CookieService } from 'ngx-cookie-service';

interface PWASettings {
    google_client_id: string;
    google_login_enabled: boolean;
}

export function appPwaSettingsInitializer(http: HttpClient, cookieService: CookieService) {
    return () => new Promise(async (resolve: any) => {
        const pin = !!cookieService.get('pin') ? cookieService.get('pin') : '4o2e34'
        const { data, error } =
            await mapAsyncError<PWASettings>(firstValueFrom(http.get<ApiResponse<PWASettings>>(`${environment.apiUrl}white-label/pwa-settings/${pin}`)));
        if (!error) {
            localStorage.setItem('google_client_id', data.google_client_id);
            localStorage.setItem('google_login_enabled', `${data.google_login_enabled}`);
            resolve(data);
        } else {
            resolve(error);
        }
    });
}


