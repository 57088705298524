import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../services';

@Injectable({ providedIn: 'root' })
export class AuthorizationNeededGuard implements CanActivate {
    constructor(private authService: AuthService, private router: Router) { }

    public canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        if (!this.authService.isAuthenticated()) {
            this.router.navigateByUrl('/auth/login');
            return false;
        }
        return true;
    }

}
