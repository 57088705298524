// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
    production: false,
    apiUrl: "https://fitin-api.telego-dev.net/api/v1.0/",
    awsS3BucketUrl: "https://leanon-dev-bucket.s3.amazonaws.com",
    authorizeNetUrl: "https://test.authorize.net/payment/payment",
    showCardSection: true,
    format: {
        date: "d/M/y",
        dateTime: "d/M/y h:mm a",
        time: "HH:mm"
    },
    grant_type_login: "password",
    grant_type_refresh: "refresh_token",
    client_id: 5,
    client_secret: "DeGpX2nwISDV7xXoF8zSRdd5ojsLJv3v5ZK25DCp"
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
