import { AfterViewInit, ElementRef } from '@angular/core';
import { Directive } from '@angular/core';

@Directive({
    selector: '[detectTextBreaks]'
})
export class TextBreakDirective implements AfterViewInit {

    private ref: ElementRef;

    constructor(el: ElementRef) {
        this.ref = el;
    }

    ngAfterViewInit(){
        this.format();
    }

    public format(){
        if (!this.ref.nativeElement.innerHTML){
            return;
        }
        this.ref.nativeElement.classList.add('multiline-wrapper');
        let fragment = new DocumentFragment();

        let text = this.ref.nativeElement.innerHTML;
        let desc = JSON.stringify(text);
        let result = desc.split('\\n');
        result = result.map(word => {
            if (word.charAt(0) === '"') {
                word = word.substring(1);
            }
            if (word.charAt(word.length - 1) === '"') {
                word = word.substring(0, word.length - 1);
            }
            return word;
        });
        result.forEach(word => {
           let span = document.createElement('span');
            span.classList.add('multiline-text');
            span.classList.add('mb-0');
            span.textContent = word;
            fragment.appendChild(span);

        })
        this.ref.nativeElement.innerHTML = '';
        this.ref.nativeElement.appendChild(fragment);
    }

}
