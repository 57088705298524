
import { NgModule } from "@angular/core";
import { PlatformDirective } from "./ios-directive";
import { TextBreakDirective } from "./multiline-text-directive";


@NgModule({
    declarations: [PlatformDirective, TextBreakDirective],
    imports: [],
    exports: [PlatformDirective, TextBreakDirective]
})
export class DirectivesModule { }
