import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { NotificationsService } from './notifications.service';
import { environment } from '../../../environments/environment';
import { firstValueFrom } from 'rxjs';
import { mapAsyncError } from '../helpers';
import { ApiResponse } from '../types';
import { TokenStorageService } from './token-storage.service';

export interface FitList<T> {
    current_page: number;
    first_page_url: string;
    from: number;
    last_page: number;
    last_page_url: string;
    next_page_url: string;
    path: string;
    per_page: number;
    prev_page_url: null
    to: number;
    total: number;
    data: T[];
}

@Injectable({ providedIn: 'root' })
export class FitApiClientService {
    constructor(
        private http: HttpClient,
        private notifications: NotificationsService,
        private tokenStorageService: TokenStorageService) { }

    public async get<T>(path: string, params?: { [param: string]: string }) {
        const headers = this.getPin();
        const url = await this.createUrl(path);
        const { data, error } = await mapAsyncError<T>(firstValueFrom(this.http.get<ApiResponse<T>>(url, { params, headers })));
        if (error) {
            this.notifications.error('Error', error.errorMessages.join('\r\n'));
        }
        return { data, error };
    }

    public async post<T>(path: string, body) {
        const headers = this.getPin();
        const url = await this.createUrl(path);
        const { data, error } = await mapAsyncError<T>(firstValueFrom(this.http.post<ApiResponse<T>>(url, body, { headers })));
        if (error) {
            this.notifications.error('Error', error.errorMessages.join('\r\n'));
        }
        return { data, error };
    }

    public async delete<T>(path: string, params?: { [param: string]: string }) {
        const headers = this.getPin();
        const url = await this.createUrl(path);
        const { data, error } = await mapAsyncError<T>(firstValueFrom(this.http.delete<ApiResponse<T>>(url, { params, headers })));
        if (error) {
            this.notifications.error('Error', error.errorMessages.join('\r\n'));
        }
        return { data, error };
    }

    public async put<T>(path: string, body: any, params?: { [param: string]: string }) {
        const headers = this.getPin();
        const url = await this.createUrl(path);
        const { data, error } = await mapAsyncError<T>(firstValueFrom(this.http.put<ApiResponse<T>>(url, body, { params, headers })));
        if (error) {
            this.notifications.error('Error', error.errorMessages.join('\r\n'));
        }
        return { data, error };
    }

    private getPin() {
        return !!this.tokenStorageService.getPin() ? new HttpHeaders({ 'x-white-label-application-pin': this.tokenStorageService.getPin() }) : new HttpHeaders({ 'x-white-label-application-pin': '4o2e34' });
    }

    private async createUrl(path: string) {
        return environment.apiUrl + path;
    }
}
