
import { CookieService } from 'ngx-cookie-service';
import { environment } from 'src/environments/environment';
import { TokenStorageService } from '../services';

export function appStyleInitializer(cookieService: CookieService, tokenStorageService: TokenStorageService) {
    return () => new Promise(async (resolve: any) => {
        const cookieValue = !!cookieService.get('pin') ? '/' + cookieService.get('pin') : '';
        tokenStorageService.savePinApp(!!cookieService.get('pin') ? cookieService.get('pin') : '4o2e34');
        const head = document.getElementsByTagName('head')[0];
        const style = document.createElement('link');
        style.setAttribute('rel', 'stylesheet');
        style.setAttribute('href', `${environment.awsS3BucketUrl}/pwa${cookieValue}/style.css`);
        head.appendChild(style);

        const manifest = document.createElement('link');
        manifest.setAttribute('rel', 'manifest');
        manifest.setAttribute('href', `${environment.awsS3BucketUrl}/pwa${cookieValue}/manifest.webmanifest`);
        head.appendChild(manifest);

        const iosIcon = document.createElement('link');
        iosIcon.setAttribute('rel', 'apple-touch-icon');
        iosIcon.setAttribute('href', `${environment.awsS3BucketUrl}/pwa${cookieValue}/assets/app_icons/icon-180x180.png`);
        head.appendChild(iosIcon);
        resolve();
    });
}
