import { Injectable } from '@angular/core';

const TOKEN_KEY = 'auth-token';
const REFRESHTOKEN_KEY = 'auth-refreshtoken';
const PIN_APP = 'pin_application';

export function tokenGetter() {
    return localStorage.getItem(TOKEN_KEY);
}

@Injectable({
    providedIn: 'root'
})
export class TokenStorageService {
    constructor() { }

    logout(): void {
        localStorage.removeItem(TOKEN_KEY);
        localStorage.removeItem(REFRESHTOKEN_KEY);
        localStorage.removeItem(PIN_APP);
    }

    public saveToken(token: string): void {
        localStorage.removeItem(TOKEN_KEY);
        localStorage.setItem(TOKEN_KEY, token);
    }

    public getToken(): string | null {
        return localStorage.getItem(TOKEN_KEY);
    }

    public saveRefreshToken(token: string): void {
        localStorage.removeItem(REFRESHTOKEN_KEY);
        localStorage.setItem(REFRESHTOKEN_KEY, token);
    }

    public getRefreshToken(): string | null {
        return localStorage.getItem(REFRESHTOKEN_KEY);
    }

    public savePinApp(pin: string): void {
        localStorage.removeItem(PIN_APP);
        localStorage.setItem(PIN_APP, pin);
    }

    public getPin(): string | null {
        return localStorage.getItem(PIN_APP);
    }
}
